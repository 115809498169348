<template>
  <div class="new-banner-block">
    <template v-if="bannerList.length > 0">

      <el-carousel
        ref="myCarousel"
        :height="height+'px'"
        @change="changeLabelItem"
        indicator-position="none"
      >
        <el-carousel-item
          v-for="(item,i) in bannerList"
          :key="i"
        >
          <el-image
            @click="toLink(item.href)"
            :style="{'width':width>0?width:'100%','height':'600px','cursor':'pointer'}"
            fit="fill"
            :src="item.picUrl"
          ></el-image>
        </el-carousel-item>
      </el-carousel>
      <banner-label
        v-if="showLabel"
        :labels="bannerList"
        :active="active"
        @labelChange="labelChange"
        @labelArrow="labelArrow"
      ></banner-label>
    </template>

  </div>

</template>
<script>
import BannerLabel from '@/components/BannerLabel.vue';

export default {
  name: 'newBanner',
  components: { BannerLabel },
  props: {
    bannerList: {},
    height: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 0
    },
    showLabel: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      active: 0
    }
  },
  methods: {
    changeLabelItem(e) {
      this.active = e;
    },
    labelChange(index) {
      this.active = index;
      this.$refs.myCarousel.setActiveItem(this.active)
    },
    labelArrow(type) {
      if (this.bannerList.length > 1) {
        let newActive = 0;
        if (type == 'last') {
          newActive = this.active - 1 < this.bannerList.length ? this.active + 1 : this.active - 1;
        } else {
          newActive = this.active + 1 > this.bannerList.length ? this.active - 1 : this.active + 1;
        }
        this.active = newActive;
        this.$refs.myCarousel.setActiveItem(newActive)
      }
    },
    toLink(link) {
      if (link && link != '')
        window.open(link);
    }
  }

}
</script>
<style lang="scss" scoped>
.new-banner-block {
  position: relative;
}
</style>