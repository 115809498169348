<template>
  <div class="global_navbar-wrapper">
    <div class="container">
      <div class="leftpad">
        <div class="logo">
          <a href="/">
            <img
              v-if="titleConfig.siteLogo"
              :src="titleConfig.siteLogo"
              alt="logo"
            >
            <img
              v-else
              src="@/assets/image/logo-new.png"
              alt="logo"
            >
          </a>
        </div>

        <div class="navbar">
          <el-menu
            :default-active="activeIndex"
            mode="horizontal"
            :router="true"
            ref="elMenu"
          >
            <el-menu-item
              class="hidden-xxs"
              index="/"
            >首页</el-menu-item>
            <el-menu-item
              class="hidden-xxs"
              index="/lab"
            >实验室</el-menu-item>
            <el-menu-item
              class="hidden-xs"
              index="/curriculum"
            >课程</el-menu-item>
            <el-menu-item
              class="hidden-s"
              v-if="titleConfig.courseProvision"
              index="/curriculumsystem"
            >课程介绍</el-menu-item>
            <el-menu-item
              class="hidden-l"
              v-if="titleConfig.famousTeacher"
              index="/teachers"
            >名师团</el-menu-item>
            <el-menu-item
              class="hidden-xl"
              index="/questionBank"
            >题库</el-menu-item>
            <el-menu-item
              class="hidden-xxl"
              index="/campus"
            >校园</el-menu-item>
            <el-menu-item
              class="hidden-xxl"
              index="/community"
            >社区</el-menu-item>
            <el-menu-item
              class="hidden-xxl"
              index=""
              @click.native="toMatch"
            >
              赛事
              <!-- <router-link tag="a" class="fa fa-edit link-btn" target="_blank" :to="{path:'/match'}">赛事</router-link> -->
            </el-menu-item>
            <el-menu-item
              class="hidden-xxl"
              index="/news"
            >新闻动态</el-menu-item>
            <el-menu-item
              v-if="!titleConfig || !titleConfig.portalDomain"
              class="hidden-xxl"
              @click.native="menuClick"
            ><a
                style="display:inline-block;"
                :href="examHref"
                target="_blank"
              >考试中心</a></el-menu-item>
            <el-submenu
              class="el-menu-more"
              mode="horizontal"
              index="/more"
            >
              <template slot="title">更多</template>
              <el-menu-item
                class="menu-item-xxs"
                index="/"
              >首页</el-menu-item>
              <el-menu-item
                class="menu-item-xxs"
                index="/lab"
              >实验室</el-menu-item>
              <el-menu-item
                class="menu-item-xs"
                index="/curriculum"
              >课程</el-menu-item>
              <el-menu-item
                class="menu-item-s"
                v-if="titleConfig.courseProvision"
                index="/curriculumsystem"
              >课程介绍</el-menu-item>
              <el-menu-item
                class="menu-item-l"
                v-if="titleConfig.famousTeacher"
                index="/teachers"
              >名师团</el-menu-item>
              <el-menu-item
                class="menu-item-xl"
                index="/questionBank"
              >题库</el-menu-item>
              <el-menu-item
                class="menu-item-xxl"
                index="/campus"
              >校园</el-menu-item>
              <el-menu-item
                class="menu-item-xxl"
                index="/community"
              >社区</el-menu-item>
              <el-menu-item
                class="menu-item-xxl"
                index=""
                @click.native="toMatch"
              >
                赛事
                <!-- <router-link style="color: gray;" tag="a" class="fa fa-edit link-btn" target="_blank" :to="{path:'/match'}">赛事</router-link> -->
              </el-menu-item>
              <el-menu-item
                class="hidden-xxl"
                index="/news"
              >新闻动态</el-menu-item>
              <el-menu-item
                class="menu-item-xxl"
                v-if="!titleConfig || !titleConfig.portalDomain"
                index="/ec"
              ><a
                  style="color: gray;"
                  :href="examHref"
                  target="_blank"
                >考试中心</a></el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
      </div>
      <div class="sidepad">
        <el-menu
          class="el-menu-demo"
          mode="horizontal"
          v-if="isLogin"
        >
          <el-submenu
            index="/user-opt"
            class="el-menu-uc"
          >
            <template slot="title">
              <el-avatar
                style="width: 30px;height:30px;margin-right: 10px;"
                :src="userInfo.avatar?userInfo.avatar:require('@/assets/image/default-avatar.png')"
              ></el-avatar>
              {{userInfo.realname}}
            </template>
            <el-menu-item
              index="/studyData"
              @click="goTo('/studyData')"
              :disabled="titleConfig.serviceLevel==1?true:false"
            >上课日历</el-menu-item>
            <el-menu-item
              index="/user"
              @click="goTo('/user')"
            >个人资料</el-menu-item>
            <el-menu-item
              index="/userinfo"
              @click="goTo('/userinfo')"
            >作品中心</el-menu-item>
            <el-menu-item
              index="/mywork"
              @click="goTo('/mywork')"
            >分析报告</el-menu-item>
            <el-menu-item
              index="/shoppingCenter"
              @click="goTo('/shoppingCenter')"
            >礼物商城</el-menu-item>
            <el-menu-item
              index="/questionCenter"
              @click="goTo('/questionCenter')"
              :disabled="titleConfig.serviceLevel==1?true:false"
            >题目中心</el-menu-item>
            <el-menu-item
              index="/ec"
              @click="goTo('/ec')"
              :disabled="titleConfig.serviceLevel==1?true:false"
            >我的考试</el-menu-item>
            <el-menu-item
              index="/logout"
              @click="logoutConfirm"
            >退出</el-menu-item>
          </el-submenu>
        </el-menu>
        <ul
          class="ul"
          v-else
        >
          <li
            class="li"
            @click="goTo('/login')"
          >登录</li>
          <li
            class="li"
            @click="toReg"
          >注册</li>
        </ul>
      </div>
      <Login :dialogFormVisible.sync="showLoginModal"></Login>
    </div>
  </div>
</template>

<script>
import Login from '@/components/LoginForm';
import { getUserInfo } from '@/utils/auth';
import { logout } from '@/api/user';
import { MessageBox } from 'element-ui';
import { mapState } from 'vuex';

export default {
  name: 'HeaderNav',
  components: {
    Login,
  },
  data() {
    return {
      activeIndex: this.$route.meta.menuIndex,
      showLoginModal: false,
      hideMenu: ["/studyData", "/userinfo", "/questionCenter", "/ec", "/logout"]
    };
  },
  watch: {
    $route(newRoute) {
      if (this.hideMenu.includes(newRoute.meta.menuIndex)) {
        this.activeIndex = '/user-opt'
        //this.$refs.elMenu.activeIndex = null
        return
      }
      this.activeIndex = newRoute.meta.menuIndex;
    },
  },
  computed: {
    isLogin: function () {
      let userInfo = getUserInfo();
      return !!userInfo;
    },
    userInfo: function () {
      let u = this.$store.state.userInfo
      return (u && u.id) ? u : getUserInfo();
    },
    ...mapState({
      titleConfig: (state) => state.siteConfigs,
    }),
    avatarKey() {
      return "header-nav-" + this.userInfo.avatar
    }
  },
  created() {
  },
  methods: {
    toMatch() {
      window.open(process.env.VUE_APP_PROTAL + "/match?ref=" + window.location.protocol + '//' + this.titleConfig.portalDomain, "_blank")
    },
    menuClick() { },
    logoutConfirm: function () {
      let that = this;
      MessageBox({
        type: 'warning',
        title: '系统提示',
        message: '您确定要退出登录吗',
      }).then(() => {
        that.$store.dispatch('LogoutUser');
        window.location.reload();
      });
    },
    goTo(path) {
      this.$router.push(path);
    },
    toReg() {
      this.$router.push({
        path: '/login',
        query: {
          type: 'reg',
        },
      });
    },
    openBlank(loanOrderId, userId) {
      let routeData = this.$router.resolve({
        name: 'orderDetail',
        params: { loanOrderId, userId }
      });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/views/global_navbar.scss';
.el-menu-more a {
  color: gray;
}
.logo img {
  max-height: 60px;
}
</style>
<style>
.global_navbar-wrapper .el-menu--horizontal > .el-menu-item.is-active {
  line-height: 61px;
}
.el-menu--horizontal > .el-menu-more.is-active .el-submenu__title,
.el-menu--horizontal > .el-menu-uc.is-active .el-submenu__title {
  border-bottom: 0px !important;
}
.global_navbar-wrapper .el-menu--horizontal .el-menu-item a {
  position: relative;
  top: -1px;
}
</style>
