export const ACCESS_TOKEN = 'Access-Token'
export const USER_NAME = 'Login_Username'
export const USER_ID = 'Login_Userid'
export const USER_INFO = 'Login_Userinfo'
export const USER_MULTI_DEPART = 'Login_User_MultiDepart'
export const USER_DEPARTS = 'Login_User_Departs'
export const USER_AUTH = 'LOGIN_USER_BUTTON_AUTH'
export const SYS_BUTTON_AUTH = 'SYS_BUTTON_AUTH'
export const ENCRYPTED_STRING = 'ENCRYPTED_STRING'
export const ENHANCE_PRE = 'enhance_'
export const UI_CACHE_DB_DICT_DATA = 'UI_CACHE_DB_DICT_DATA'
export const INDEX_MAIN_PAGE_PATH =  "/dashboard/analysis"

export const CONTENT_WIDTH_TYPE = {
  Fluid: 'Fluid',
  Fixed: 'Fixed'
}