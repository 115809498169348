<template>
  <div class="main-home">
    <banner-bg :type="0">
      <div class="main-home-base">
        <div class="main-home-banner">
          <new-banner
            :bannerList="bannerList"
            :height="600"
          ></new-banner>
        </div>
      </div>
    </banner-bg>
    <div
      class="announce-block"
      v-show="competitionList && competitionList.length > 0"
    >
      <div class="wow lightSpeedIn advert-top">
        <div class="ico-horn">
          <img :src="require('@/assets/image/announce.png')">
        </div>
        <span class="competition-title">亿启编头条：</span>
        <div class="competition-list">
          <el-carousel
            :interval="3000"
            direction="vertical"
            arrow="never"
            height="23px"
            ref="top-carousel2"
          >
            <el-carousel-item
              v-for="(item,index) in competitionList"
              :key="index"
              @click.native="toArticle(item.id)"
            >
              {{item.title?item.title:""}}
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>

    <div class="word-block">
      <banner-bg
        :type="11"
        :defaultBg="require('@/assets/image/Home/WordCommonBg.jpg')"
        bgColor="#ffffff"
      >
        <div class="title-body">
          <p class="p1"><span>{{titleConfig.indexTitle1}}</span></p>
          <p class="p2">{{titleConfig.indexTitle2}}</p>
          <p class="p3">{{titleConfig.indexTitle3}}</p>
        </div>
      </banner-bg>

    </div>
    <div
      class="main-home-content content-1"
      style="padding-top:50px;height:850px"
    >
      <Title
        title="选择好工具，编程更轻松"
        desc="云端创作矩阵，丰富的在线编程工具"
      ></Title>
      <div class="main-home-body body-1">
        <div class="labs-content">
          <div class="labs-desc">
            <p class="title">{{currentLabData.title}}</p>
            <p class="desc">{{currentLabData.desc}}</p>
            <p
              class="info"
              v-html="currentLabData.info"
            ></p>
            <div class="footer">
              <a
                :href="currentLabData.link"
                target="_blank"
                class="button"
              >立即创作</a>
            </div>
          </div>
          <img :src="currentLabData.gif" />
          <div class="footer2">
            <p>作品展示</p>
            <a
              :href="`/community?lab=${currentLabData.id}`"
              class="a"
              target="_blank"
            >了解更多作品>></a>
          </div>
        </div>
        <div class="works-show">
          <img
            v-for="(item,i) in currentLabData.worksImg"
            :key="i"
            :src="item"
          >
        </div>
        <div class="labs-label">
          <ul>
            <li
              @click="changeLab(index)"
              :class="{active: currentLab == index}"
              v-for="(item, index) in labsData"
              :key="item.id"
            >
              <div class="icon">
                <img :src="item.icon">
              </div>
              <div class="menu">
                <p class="title">{{item.title}}</p>
              </div>
            </li>
          </ul>

        </div>

      </div>
    </div>
    <div class="main-home-content">
      <Title
        title="热门编程课，名师带你学"
        desc="丰富的图形、代码、算法编程课程"
      ></Title>
      <div class="course-banner-block">
        <div>
          <div class="img">
            <div class="banner-block">
              <el-carousel
                height="600px"
                indicator-position="none"
              >
                <el-carousel-item
                  v-for="(item,key) in bannerList2"
                  :key="key"
                >
                  <a
                    :href="item.href"
                    target="_blank"
                  >
                    <el-image
                      style="height:600px;width:100%"
                      fit="fill"
                      :src="item.picUrl"
                    ></el-image>
                  </a>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
      </div>
      <div class="main-home-body">
        <Curriculums :datas="curriculums"></Curriculums>
        <p class="more">
          <a
            href="/curriculum"
            target="_blank"
          >更多课程>></a>
        </p>
      </div>
    </div>
    <banner-bg
      :type="1"
      bgColor="#ffffff"
    >
      <div class="main-home-content content-2">
        <Title
          title="精彩编程作品，二次创意改编"
          desc="看看社区中的热门作品吧"
        ></Title>
        <div class="main-home-body">
          <ProgramList
            :datas="worksList"
            :type="3"
            style="margin-top: 48px;"
          ></ProgramList>
          <p class="more">
            <a
              href="/community"
              target="_blank"
            >更多作品>></a>
          </p>
        </div>
      </div>
    </banner-bg>
    <!-- <div class="main-home-content">
      <Title title="全国合作学校" desc="全国14个省份，1000+所学校引入亿启编平台"></Title>
      <div class="main-home-body">
        <ul class="teacher-title">
          <li v-for="(item, index) in teacherTitle" :key="index">
            <div class="img">
              <img :src="item.pic" alt="">
            </div>
            <p class="title">{{item.title}}</p>
            <p class="desc">{{item.desc}}</p>
          </li>
        </ul>
        <ul class="teachers">
          <li v-for="(item, index) in teachers" :key="index">
            <div>
              <img :src="item.pic" alt="">
            </div>
          </li>
        </ul>
        <p class="more">
          <a href="/campus" target="_blank">查看校园介绍>></a>
        </p>
      </div>
    </div> -->
  </div>
</template>

<script>
import { getBanners, getNews } from '@/api/common';
import Title from '@/components/Title';
import Curriculums from '@/components/Curriculums';
import ProgramList from '@/components/ProgramList';
import { HOME, LABS } from '@/utils/page-resource';
import { courseTags, courses } from '@/api/course';
import { getWorks } from "@/api/community"
import { mapState } from 'vuex';
import BannerBg from '@/components/BannerBg.vue';
import NewBanner from '@/components/NewBanner.vue';
//import { url } from 'inspector';
export default {
  components: {
    Title,
    Curriculums,
    ProgramList,
    BannerBg,
    NewBanner
  },
  mounted() {
    this.getBannerList();
    this.getBannerList2();
    this.getNewsList();
    this.getData();
  },
  data() {
    return {
      competitionList: [],
      bannerShow: false,
      currentLabData: LABS[0],
      currentLab: 0,
      worksList: [],
      labsData: LABS,
      bannerList: [],
      bannerList2: [],
      curriculums: [],
      teacherTitle: HOME.teacherTitle,
      teachers: HOME.teachers,
      allTags: []
    };
  },
  computed: mapState({
    titleConfig: (state) => state.siteConfigs,
  }),
  methods: {
    getNewsList() {
      getNews({ firstRotation: 1 }).then(res => {
        if (res.success) {
          this.competitionList = res.result.records;
        } else {
          this.$message.error(res.message);
        }
      })
    },
    toArticle(id) {
      this.$router.push('/newsDetail?id=' + id)
    },
    getBannerList() {
      let param = {
        available: 1,
        pageSize: 99,
        location: 'sydb',
        platform: 'yqb',
        isPortal: '1'
      }
      getBanners(param).then(res => {
        if (res.success) {
          this.bannerShow = true;
          this.bannerList = res.result.records;
        } else {
          this.$message.error(res.message);
        }
      })
    },
    getBannerList2() {
      let param = {
        available: 1,
        pageSize: 99,
        location: 'sykc',
        platform: 'yqb',
        isPortal: '1'
      }
      getBanners(param).then(res => {
        if (res.success) {
          this.bannerList2 = res.result.records;
        } else {
          this.$message.error(res.message);
        }
      })
    },
    getTags() {
      courseTags({}).then(res => {
        if (res.success) {
          this.allTags = res.result.courseTypes ?? [];
          this.getCurriculum();
        }
      })
    },
    changeLab(index) {
      this.currentLab = index;
      this.currentLabData = this.labsData[index];
    },
    getCurriculum() {
      courses({ isHot: 1 }).then(res => {
        if (res.success && res.result.records.length > 0) {
          this.curriculums = res.result.records.splice(0, 3).map(item => {
            return {
              id: item.id,
              avator: item.author.avator,
              courseCover: item.courseCover,
              userlink: item.author.link,
              name: item.courseName,
              ke: item.hour,
              author: item.author.name,
              userId: item.author.userId || '1',
              star: item.star,
              up: item.up,
              views: item.view,
              // tags: (item.tags ? item.tags.split(',') : []).map(item => {
              //   return this.allTags[item]
              // }),
              desc: item.courseDesc,
              isStar: false,
              isCollect: false,
              typeName: item.typeName,
              grade: (item.grade ?? '').split('/')[0],
              difficulty: item.difficulty
            }
          });
        }
      })
    },
    getData() {
      getWorks({}).then(res => {
        if (res.success) {
          this.worksList = res.result.records.splice(0, 8).map(item => {
            return Object.assign({}, item, { star: false, collect: false, tags: [item.fileType_dictText, item.tagDifficulty] })
          });
        }
      })
      this.getTags();
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/views/home-index.scss';
@import '~@/assets/css/views/animate.css';
</style>