<template>
  <div
    id="app"
    class="app_root-container"
  >
    <!-- 无头无底页面（登录/注册）full-page -->
    <div
      class="full-page"
      v-if="isFullPage"
    >
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive">
      </router-view>
      <div class="footer">
        <a
          style=""
          href="http://beian.miit.gov.cn/state/outPortal/loginPortal.action"
          class="record"
          target="_blank"
        >渝ICP备2021009025号-1
        </a> 联系我们：team@eqibian.com
      </div>
    </div>

    <!-- 有头有底页面 page -->
    <div
      v-else
      class="page"
    >
      <router-view name="header"></router-view>
      <div
        class="main-container"
        :class="($route.path && $route.path.indexOf('match')>-1) ? 'main-container2' : ''"
      >
        <keep-alive v-if="$route.meta.keepAlive">
          <router-view :style="mainStyle" />
        </keep-alive>
        <router-view
          v-else
          :style="mainStyle"
        >
        </router-view>
        <Footer v-if="!$route.meta.hideFooter"></Footer>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav.vue';
import Footer from '@/components/Footer.vue';
import { getMyConcerns } from '@/api/user'
import { getConfigs } from '@/api/common'
import { mapState } from 'vuex'
import Vue from 'vue'
import { ACCESS_TOKEN } from '@/store/mutation-types';

export default {
  name: "FixTop",
  data() {
    return {
      activeIndex: '1',
      hasHeader: true,
      isFullPage: false
    };
  },
  components: {
    HeaderNav,
    Footer,
  },
  watch: {
    '$route.path': {
      handler(val) {
        this.isFullPage = val == '/login' || val == '/agreement' ? true : false;

      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapState({
      titleConfig: (state) => state.siteConfigs,
    }),
    mainStyle() {
      return this.$route.meta.hideFooter ? { minHeight: ((window.screen.height - 170) + 'px') } : { minHeight: ((window.screen.height - 451) + 'px') }
    }
  },
  created() {
    // console.log(this.titleConfig.isInit == false, this.isFullPage == false)
    //if(this.titleConfig.isInit == false && this.isFullPage == false && this.$route.path != '/login'){
    //this.getSchoolConfig();
    //}
    // this.checkToken();
    // const path = window.location.pathname;
    // if(path.includes("teaching")){
    //   this.hasHeader = false;
    // }
  },
  methods: {
    checkToken() {
      getMyConcerns({ workIds: [], type: 'WORK' }).then(res => { })
    },
    getSchoolConfig() {
      getConfigs().then(res => {
        if (res.success) {
          let data = res.result;
          if (data) {
            let siteConfigs = [];
            // data.siteConfig = null;
            if (data.siteConfig && data.siteConfig.length > 0) {
              let siteConfigArray = JSON.parse(data.siteConfig);
              //let bannerConfig = JSON.parse("{\"bottomChart\":{\"banner0\":\"http://119.23.227.135:9000/public/temp202206/首页顶部底图.png\",\"banner1\":\"http://119.23.227.135:9000/public/temp202206/首页精彩作品区域底图.png\",\"banner2\":\"http://119.23.227.135:9000/public/temp202206/首页顶部底图.png\",\"banner3\":\"http://119.23.227.135:9000/public/temp202206/首页顶部底图.png\",\"banner4\":\"http://119.23.227.135:9000/public/temp202206/首页顶部底图.png\",\"banner5\":\"http://119.23.227.135:9000/public/temp202206/首页顶部底图.png\",\"banner6\":\"http://119.23.227.135:9000/public/temp202206/首页顶部底图.png\",\"banner7\":\"http://119.23.227.135:9000/public/temp202206/首页顶部底图.png\",\"banner8\":\"http://119.23.227.135:9000/public/temp202206/首页顶部底图.png\",\"banner9\":\"https://teaching-works-public.oss-cn-shenzhen.aliyuncs.com/upload/test/UserAvatar1_1654846373992.png\",\"banner10\":\"http://119.23.227.135:9000/public/temp202206/统一登录页指示图.svg\"}}").bottomChart
              let bannerConfig = data.bottomChart ? JSON.parse(data.bottomChart).bottomChart : {}
              siteConfigs = {
                ...bannerConfig,
                indexTitle1: siteConfigArray['【首页】标题1'],
                indexTitle2: siteConfigArray['【首页】副标题1'],
                indexTitle3: siteConfigArray['【首页】副标题2'],
                schoolTitle1: siteConfigArray['【校园】标题1'],
                schoolTitle2: siteConfigArray['【校园】副标题1'],
                schoolTitle3: siteConfigArray['【校园】副标题2'],
                siteLogo: data.siteLogo,
                portalDomain: data.portalDomain,
                backDomain: data.backDomain,
                isInit: true,
                serviceLevel: data.serviceLevel,
                courseProvision: data.courseProvision,
                famousTeacher: data.famousTeacher ? data.famousTeacher : true
              }
              this.$store.dispatch('SetSiteConfigs', siteConfigs);
            }
          }
        }
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.app_root-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  .main-container {
    height: calc(100% - 59px);
    overflow: hidden;
    overflow-y: auto;
    overflow-x: auto;
  }

  .main-container2 {
    height: calc(100% + 1px);
  }

  .page {
    height: 100%;
  }
}
</style>
<style lang="scss">
.table-pagination {
  display: flex;
  justify-content: right;
  margin: 15px;
}
.fix-opts {
  right: 50px;
  bottom: 100px;
  z-index: 10;
  position: fixed;
  cursor: pointer;

  li {
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 10px;
    box-shadow: 0px 0px 4px rgb(74 89 111 / 20%);
    //border-bottom: 1px solid #eff3f8;

    span {
      display: block;
    }

    img {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      color: rgb(0, 129, 239);
    }
  }
}
</style>
<style>
.tip-text {
  display: block;
  text-align: center;
  margin: 10px auto;
}
.el-menu.el-menu--horizontal {
  border-bottom: 0px !important;
}
</style>
