/*
 * @Author: your name
 * @Date: 2021-01-25 14:35:11
 * @LastEditTime: 2021-01-25 14:35:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /scratch_project/src/utils/index.js
 */
import axios from 'axios';

export const download = (
  data,
  fileName = 'excel.xlsx',
  type = 'application/octet-stream',
  id = 'downloadExcel',
) => {
  if (!data) {
    return;
  }
  let url;
  let blob;
  const isString = typeof data === 'string';
  if (!isString) {
    blob = new Blob([data], {
      type, // 下载的文件类型格式（二进制流，不知道下载文件类型可以设置为这个）, 具体请查看HTTP Content-type 对照表
    });
    url = window.URL.createObjectURL(blob);
  } else {
    url = data;
  }
  if (!isString && window.navigator.msSaveBlob) {
    try {
      window.navigator.msSaveBlob(blob, fileName);
    } catch (e) {
      // console.log(e);
    }
  } else {
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.id = 'downloadExcel';
    // if (!isString) {
    link.setAttribute('download', fileName);
    // }
    document.body.appendChild(link);
    link.click();
    const el = document.querySelector(`#${id}`);
    el.parentNode.removeChild(el);
    if (!isString) {
      URL.revokeObjectURL(url); // 释放掉blob对象
    }
  }
};

export function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      resolve(e.target.result);
    };
    fileReader.readAsDataURL(blob);
    fileReader.onerror = () => {
      reject(new Error('文件流异常'));
    };
  });
}

export function blobUrl(blobObj, parentNode) {
  const blob = new Blob([blobObj], {
    type: 'image/jpeg',
  });
  const img = document.createElement('img');
  img.src = window.URL.createObjectURL(blob);
  this.$nextTick(() => {
    parentNode.appendChild(img);
  });
  img.onload = () => {
    window.URL.revokeObjectURL(img.src); // 清除释放
  };
}

export const exportExcel = async ({ url, data }) => {
  const res = await axios({
    method: 'post',
    responseType: 'blob',
    url: `${window.configPath}${url}`,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
    data,
  });
  const fileName = decodeURIComponent(
    res.headers['content-disposition'].split('=')[1],
  );
  download(res.data, fileName, 'application/vnd.ms-excel');
};

//解析url参数
export function getQueryVal(variable){
  var query = decodeURIComponent(window.location.search.substring(1))
  var vars = query.split("&");
  for (var i=0;i<vars.length;i++) {
    let j = vars[i].indexOf('=')
    var pn = vars[i].substring(0,j)
    var pv = vars[i].substring(j+1)
    if(pn == variable){return pv;}
  }
  return(false);
}

export function removeURLParameter111(url, parameter) {
  url = decodeURIComponent(url)
  let index = url.indexOf('?')
  var urlparts = url.substring(index+1)
  if(urlparts.length >= 2) {
    //参数名前缀
    var prefix = encodeURIComponent(parameter) + '=';
    var pars = urlparts[1].split(/[&;]/g);

    //循环查找匹配参数
    for(var i = pars.length; i-- > 0;) {
      if(pars[i].lastIndexOf(prefix, 0) !== -1) {
        //存在则删除
        pars.splice(i, 1);
      }
    }

    return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  }
  return url;
}

export function removeURLParameter(url, param){
  url = decodeURIComponent(url)
   let aParam = [param]
   aParam.forEach(item => {
     const fromindex = url.indexOf(`${item}=`) //必须加=号，避免参数值中包含item字符串
     if (fromindex !== -1) {
       // 通过url特殊符号，计算出=号后面的的字符数，用于生成replace正则
       const startIndex = url.indexOf('=', fromindex)
       const endIndex = url.indexOf('&', fromindex)
       const hashIndex = url.indexOf('#', fromindex)
       
       let reg;
       if (endIndex !== -1) { // 后面还有search参数的情况
         const num = endIndex - startIndex
         reg = new RegExp(`${item}=.{${num}}`)
         url = url.replace(reg, '')
       } else if (hashIndex !== -1) { // 有hash参数的情况
         const num = hashIndex - startIndex - 1
         reg = new RegExp(`&?${item}=.{${num}}`)
         url = url.replace(reg, '')
       } else { // search参数在最后或只有一个参数的情况
         reg = new RegExp(`&?${item}=.+`)
         url = url.replace(reg, '')
       }
     }
   });
   const noSearchParam = url.indexOf('=') 
   if( noSearchParam === -1 ){
     url = url.replace(/\?/, '') // 如果已经没有参数，删除？号
   }
   return url    
}

/**
 * 过滤html标签
 */
 export function htmlFormatter(html){
  //去掉html标签   去掉HTML特殊字符   去掉头尾空格
  return html.replace(/<\/?[^>]*>/g, '').replace(/&[a-zA-Z]{1,10};/g,'').replace(/[|]*\n/, '');
}

export function toLogin(isLogout){
  let reirectUri = getQueryVal('redirect_uri')
  if(reirectUri && (window.location.origin == process.env.VUE_APP_PROTAL)){
    return window.location.reload()
  }
  let url = `${process.env.VUE_APP_PROTAL}/login?from=school&isLogout=${isLogout?isLogout:false}&redirect_uri=${location.href}`
  if(reirectUri){
    url = process.env.VUE_APP_PROTAL +'/login?' + location.href.split("?")[1]
  }
  debugger
  console.log("-------tologin:", url)
  window.location.href= url
}

export default { download, exportExcel, getQueryVal, removeURLParameter, htmlFormatter, toLogin};
