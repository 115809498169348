import Vue from 'vue'
import store from '@/store'
import Cookies from 'js-cookie';
import { ACCESS_TOKEN,USER_INFO,USER_MULTI_DEPART, USER_DEPARTS } from "@/store/mutation-types"


const TokenKey = 'Token';

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function getAccessToken() {
  return Vue.ls.get(ACCESS_TOKEN)
}

export function getUserInfo() {
  return Vue.ls.get(USER_INFO)
}

export function getUserDepartInfo() {
  return { 
    multi_depart: Vue.ls.get(USER_MULTI_DEPART), 
    departs: Vue.ls.get(USER_DEPARTS), 
  }
}

//角色判断 1: '学生',   2: '教师',  3: '考试管理员',  4: '校长管理员'
export async function isRole(role){
  let user = Vue.ls.get(USER_INFO)
  let roles = user ? user.role : null
  if(!roles) roles = await store.dispatch('InitUserRole')
  if(roles) return roles.includes(role)
  return false
}

//判断是否是学生
export async function isStudent() {
  return await isRole(1)
}

//判断是否是老师
export async function isTeacher() {
  return isRole(2)
}

//判断是否是校长管理员
export async function isSchoolAdmin() {
  return isRole(4)
}

//角色判断 1: '学生',   2: '教师',  3: '考试管理员',  4: '校长管理员'
export function hasRole(role){
  let user = Vue.ls.get(USER_INFO)
  if(!user || !user.role) return false
  return user.role.includes(role)
}

