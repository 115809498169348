<!--
 * @Author: your name
 * @Date: 2021-01-27 17:13:54
 * @LastEditTime: 2021-01-28 01:14:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /portal/src/components/Footer.vue
-->
<template>
  <banner-bg
    :type="11"
    :forceBg="footerBg"
  >
    <div class="footer">
      <div class="footer-links">
        <div class="fl-logo">
          <div class="logo">
            <a href="/">
              <img
                v-if="footer.logo"
                :src="footer.logo"
                alt="logo"
              >
              <img
                v-else
                src="@/assets/image/logo-fff.svg"
                alt="logo"
              >
            </a>
            <p>{{footer.companyName}}</p>
            <p>电话：{{footer.companyContact}}</p>
            <p>邮箱：{{footer.companyEmail}}</p>
          </div>
        </div>
        <div class="fl-menu">
          <div class="fl-title">站内导航</div>
          <div
            class="fl-items"
            v-if="ref"
          >
            <div>
              <p><a :href="ref">首页</a></p>
              <p> <a :href="ref+'/lab'">实验室</a></p>
              <p><a :href="ref+'/curriculum'">课程</a></p>
              <p><a :href="ref+'/curriculumsystem'">课程介绍</a></p>
              <p> <a :href="ref+'/teachers'">名师团</a></p>
              <p> <a :href="ref+'/questionBank'">题库</a></p>
            </div>
            <div>
              <p> <a :href="ref+'/campus'">校园</a></p>
              <p> <a :href="ref+'/community'">社区</a></p>
              <p> <a :href="ref+'/match'">赛事</a></p>
              <p> <a :href="ref+'/news'">新闻动态</a></p>
            </div>
          </div>
          <div
            class="fl-items"
            v-else
          >
            <div>
              <p> <a href="/">首页</a></p>
              <p> <a href="/lab">实验室</a></p>
              <p> <a href="/curriculum">课程</a></p>
              <p> <a href="/curriculumsystem">课程介绍</a></p>
              <p> <a href="/teachers">名师团</a></p>
              <p> <a href="/questionBank">题库</a></p>
            </div>
            <div>
              <p> <a href="/campus">校园</a></p>
              <p> <a href="/community">社区</a></p>
              <p>
                <router-link
                  tag="a"
                  class="fa fa-edit link-btn"
                  target="_blank"
                  :to="{path:'/match'}"
                >赛事</router-link>
              </p>
              <p> <a href="/news">新闻动态</a>
              </p>
            </div>
          </div>
        </div>
        <div class="fl-links">
          <div class="fl-title">友情链接</div>
          <div class="fl-items">
            <div>
              <p
                v-for="item in footer.hrefList.slice(0, 5)"
                :key="item.hrefName"
              > <a
                  :href="item.href"
                  target="_blank"
                >{{item.hrefName}}</a></p>
            </div>
            <div>
              <p
                v-for="item in footer.hrefList.slice(5, 11)"
                :key="item.hrefName"
              > <a
                  :href="item.href"
                  target="_blank"
                >{{item.hrefName}}</a></p>
            </div>
          </div>
        </div>
        <div
          class="fl-qrcode"
          style="text-align:center"
        >
          <el-image
            style="width: 90px; height: 90px"
            :src="footer.qrCode?footer.qrCode:require('@/assets/image/qrcode.jpg')"
            fit="cover"
          ></el-image>
          <div style="font-size:12px;text-align:center">关注亿启编公众号获取更多服务</div>
        </div>
      </div>

      <div class="inner-wrapper">
        <div style="margin:0 auto; padding:5px 0 10px 0;">
          <img
            src="@/assets/image/filing-icon.png"
            style="float:left;width:20px"
          />
          <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px;">
            <a
              target="_blank"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010802004477"
              style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"
            >
              {{footer.archival}}</a>
            <a
              style=""
              href="http://beian.miit.gov.cn/state/outPortal/loginPortal.action"
              class="record"
              target="_blank"
            >
              {{footer.ICPInfo}}
            </a>
            <span
              href="javascript:void(0)"
              style="display:inline-block;color: #ffffff; height:20px;line-height:20px;"
            >
              联系我们：{{footer.companyEmail}}</span>
          </p>
        </div>
      </div>
    </div>
  </banner-bg>
</template>

<script>
import { mapState } from 'vuex';
import { getFooter } from '@/api/common';
import BannerBg from '@/components/BannerBg.vue';

export default {
  name: 'Footer',
  components: { BannerBg },
  data() {
    return {
      footer: {
        logo: require('@/assets/image/logo-fff.jpg'),
        qrCode: require('@/assets/image/qrcode.jpg'),
        companyName: "重庆亿启编科技有限公司",
        archival: "渝公网安备 50010802004477号",
        ICPInfo: "渝ICP备2021009025号-1",
        companyEmail: "team@eqibian.com",
        companyContact: "13527310120",
        hrefList: [],
        ref: null
      }
    }
  },
  computed: {
    ...mapState({
      titleConfig: (state) => state.siteConfigs,
    }),
    footerBg() {
      if (this.$route.path === '/rank') {
        return require('@/assets/image/footer-bg-v2.svg')
      }
      return this.footer.backBanner ? this.footer.backBanner : require('@/assets/image/footer-bg-v2.svg')
    }
  },
  created() {
    this.ref = this.getQueryVariable('ref')
    this.initData()
  },
  methods: {
    initData() {
      getFooter().then(res => {
        if (res.success && res.result && res.result.data) {
          let f = JSON.parse(res.result.data)
          if (f.hrefList) f.hrefList = JSON.parse(f.hrefList)
          else f.hrefList = []
          this.footer = f
        }
      })
    },
    //获取url参数
    getQueryVariable(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
  }
};
</script>

<style lang="scss" scoped>
.footer {
  // background: url(~@/assets/image/footer-bg-v2.svg) no-repeat center center;
  // background-size: cover;
  flex: 0 0 240px;
  height: 240px;
  color: #666;
  position: relative;
  width: 100%;
  min-width: 1200px;
  .inner-wrapper {
    height: 36px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    font-size: 12px;
    border-top: 1px solid rgb(238 238 238 / 50%);
  }
  .footer-links {
    /*border-bottom: 1px solid transparent;
    border-image: linear-gradient(to bottom,transparent 50%, #eee 50%) 0 0 100%/1px 0;*/
    width: 1000px;
    margin: 0px auto;
    padding: 10px 0px;
    display: flex;
    flex-direction: row;
    color: #ffffff;
    font-size: 14px;
  }
  .fl-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  .fl-items {
    display: flex;
    flex-direction: row;
    div {
      flex: 1;
      a {
        display: inline-block;
      }
    }
  }
  .fl-logo {
    width: 250px;
    .logo {
      img {
        width: 120px;
        max-height: 30px;
        margin-bottom: 15px;
      }
    }
  }
  .fl-menu {
    width: 250px;
  }
  .fl-links {
    width: 400px;
  }
  .fl-qrcode {
    width: 100px;
    text-align: right;
    padding-top: 50px;
  }
}
</style>
