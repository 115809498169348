<template>
  <el-dialog
    title="登录"
    :visible.sync="dialogFormVisible"
    center
    width="500px"
    :before-close="handleClose"
  >
    <el-form :model="form">
      <el-tabs v-model="activeLoginTab" :stretch="true">
        <el-tab-pane label="账号登录" name="account">
          <el-form-item>
            <el-input
              v-model="form.username" @change="inputChange"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="form.password" @change="inputChange" show-password
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="短信登录" name="msgcode">
          <p>&nbsp;</p>
          <el-form-item>
            <el-input
              v-model="form.mobile" @change="inputChange"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-row>
              <el-col :span="16">
                <el-input
                  v-model="form.msgcode" @change="inputChange"
                  placeholder="请输入验证码"
                ></el-input>
              </el-col>
              <el-col :span="2"><p>&nbsp;</p></el-col>
              <el-col :span="6">
                <el-button type="primary" plain @click="sendMsgCode"
                  >获取验证码</el-button
                >
              </el-col>
            </el-row>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="doLogin" 
        :disabled="loginBtnDisabled" :loading="loginBtnLoading">登 录</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { login } from "@/api/user"

export default {
  name: 'LoginForm',
  props: {
    dialogFormVisible: Boolean,
  },
  data() {
    return {
      activeLoginTab: 'account',
      form: {
        username: '',
        password: '',
        captcha: '',
        checkKey: '',
      },
      mobileForm: {
        mobile: '',
        msgcode: '',
      },
      loginBtnDisabled: true,
      loginBtnLoading: false
    };
  },
  methods: {
    doLogin() {
      this.loginBtnDisabled = true;
      this.loginBtnLoading = true;
      let loginData = this.form;
      let that = this;

      login(loginData).then(res=>{
        this.loginBtnDisabled = false;
        this.loginBtnLoading = false;

        if(res.success){
          that.loginSuccess(res.result);
        }else{
          that.$notify({
            title: '登录提示',
            message: res.message || '登录失败：未知错误',
            type: 'warning'
          });
        }
      }).catch(error => {
        that.$notify({
          title: '登录提示',
          message: error || '登录失败：未知错误',
          type: 'warning'
        });
      })

    },
    inputChange(){
      if(this.activeLoginTab=='account'){
        this.loginBtnDisabled = !(this.form.username && this.form.password);
      }else{
        this.loginBtnDisabled = !(this.form.mobile && this.form.msgcode);
      }
    },
    sendMsgCode() {
      console.log('sendMsgCode');
    },
    handleClose() {
      this.loginBtnLoading = false;
      this.$emit('update:dialogFormVisible', false);
    },
    loginSuccess(data){
      //登录成功后的处理：设置session信息
      this.$store.dispatch('LoginUser', data)
      this.$notify({
        //title: '登录成功',
        message: '恭喜！您已登录成功',
        type: 'success'
      });
      window.location.reload();

      // this.$router.push({ path: "/dashboard/analysis" })
      // this.$notification.success({
      //   message: '欢迎',
      //   description: `${timeFix()}，欢迎回来`,
      // });

    },
  },
};
</script>

<style></style>
