<template>
  <div class="program-list">
    <ul class="course-list base-list">
      <template v-for="(course, index) in datas">
        <li
          :key="index"
          :class="['li', `type-${type}`]"
        >
          <div class="cover-base">
            <div class="cover">
              <img
                v-if="noHover"
                crossorigin="anonymous"
                :src="course.coverFileUrl"
                alt=""
              />
              <img
                v-else
                :src="course.coverFileUrl"
                alt=""
              />
              <div
                class="evaluate-tag"
                v-if="course.evaluate == 1"
              >教师已评价</div>
            </div>
            <div
              class="part-second"
              v-if="!noHover"
            >
              <div class="course-info">
                <a
                  style="-webkit-box-orient: vertical;-webkit-line-clamp: 1;display: -webkit-box;"
                  href="javascript:;"
                  @click="goCoding(course.studyLink)"
                  class="tit"
                >{{ course.workName }}</a>
              </div>
              <div
                class="mechanism-desc"
                style="-webkit-box-orient: vertical;-webkit-line-clamp: 3;display: -webkit-box;"
              >{{ course.workDesc }}</div>
              <div class="operation">
                <el-button
                  style="width: 50%;"
                  @click="goCoding(course.studyLink)"
                  type="primary"
                  size="medium"
                >改编作品</el-button>
                <el-button
                  style="width: 50%;"
                  @click="showReport(course)"
                  type="primary"
                  size="medium"
                >作品报告</el-button>
              </div>
            </div>
          </div>
          <div class="content">
            <div class="part-one">
              <div class="course-info">
                <a
                  style="-webkit-box-orient: vertical;-webkit-line-clamp: 1;display: -webkit-box;"
                  href="javascript:;"
                  @click="goCoding(course.studyLink)"
                  class="tit"
                >{{ course.workName }}</a>
                <!-- <span class="time">{{ course.concernsNum }}课时</span> -->
              </div>
              <div class="tags">
                <template v-for="tag in course.tags">
                  <span
                    :key="tag"
                    v-if="tag"
                    class="tag"
                  >{{ tag }}</span>
                </template>
              </div>
              <div class="course-opeartion">
                <div class="fav">
                  <i
                    :class="['iconfont', course.collect ? 'iconyishoucang0081ef active': 'iconshoucang979EB1', 'can-active']"
                    @click="collectOrStar(course, 2)"
                  ></i><span class="num">{{ course.collectNum || 0 }}</span>
                </div>
                <div class="fav">
                  <i
                    :class="['iconfont icondianzan979EB1', course.star ? 'iconyidianzan0081ef active': 'icondianzan979EB1', 'can-active']"
                    @click="collectOrStar(course, 1)"
                  ></i><span class="num">{{ course.starNum || 0 }}</span>
                </div>
                <div class="fav">
                  <i class="iconfont iconguankancishu"></i><span class="num">{{ course.viewNum || 0 }}</span>
                </div>
              </div>
              <div class="mechanism">
                <div class="mech">
                  <a
                    href="javascript:;"
                    @click="goUserInfo(course.userId)"
                    class="face name"
                  ><img
                      v-if="noHover"
                      crossorigin="anonymous"
                      :src="`${course.authorAvatar}?_=${Date.now()}`"
                      style="width: 20px; display: inline-block;"
                    />
                    <img
                      v-else
                      :src="course.authorAvatar"
                      style="width: 20px; display: inline-block;"
                    />
                  </a><a
                    href="javascript:;"
                    @click="goUserInfo(course.userId)"
                    class="name"
                  >{{ course.createBy }}</a>
                </div>
              </div>
            </div>
          </div>
        </li>
      </template>
    </ul>

    <WorkReport ref="workReport"></WorkReport>
  </div>
</template>

<script>
import WorkReport from '@/components/WorkReport';

import { putLikes } from "@/api/community"
import { getMyConcerns } from '@/api/user'
import Vue from 'vue'
export default {
  name: 'program-list',
  components: { WorkReport },
  props: {
    datas: {
      type: Array,
      default() {
        return []
      },
    },
    type: {
      type: Number,
      default() {
        return 1
      },
    }
    ,
    noHover: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      workId: null
    };
  },
  mounted() {
    if (Vue.ls.get('Access-Token')) {
      this.getStarAndCollect(this.datas.map(item => item.id));
    }
  },
  watch: {
    datas(value) {
      if (Vue.ls.get('Access-Token')) {
        this.getStarAndCollect(value.map(item => item.id));
      }
    },
  },
  methods: {
    showReport(course) {
      if (course) {
        this.$refs.workReport.initReport(course)
      }
    },
    goCoding(studyLink) {
      window.open(studyLink)
    },
    goCurriculum(id) {
      this.$router.push({ path: `/curriculum-info`, query: { cid: id } });
    },
    goUserInfo(id) {
      this.$router.push({ path: `/userinfo`, query: { uid: id } });
    },
    getStarAndCollect(idList) {
      if (idList.length == 0) {
        return;
      }
      getMyConcerns({ workIds: idList.join(','), type: 'WORK' }).then(res => {
        if (res.success) {
          this.datas.forEach(item => {
            if (res.result[item.id] == 1) {
              item.star = true;
            } else if (res.result[item.id] == 2) {
              item.collect = true;
            } else if (res.result[item.id] == 3) {
              item.collect = true;
              item.star = true;
            }
          })
        }
      })
    },
    collectOrStar(item, opeType) {
      putLikes({ id: item.id, opeType: opeType, type: 'WORK' }, opeType == 2 ? item.collect : item.star).then(res => {
        if (res.success) {
          this.$notify({
            title: '系统提示',
            message: opeType == 2 ? (item.collect ? '取消收藏成功' : '收藏成功') : (item.star ? '取消点赞成功' : '点赞成功'),
            type: 'success'
          });
          if (opeType == 2) {
            item.collect = !item.collect;
            item.collectNum = parseInt(item.collectNum || 0) + (item.collect ? 1 : -1);
          } else {
            item.star = !item.star;
            item.starNum = parseInt(item.starNum || 0) + (item.star ? 1 : -1);
          }
          this.$emit('success')
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/views/program-list.scss';
</style>