export const SCHOOL = {
  bodyData1: [
    {
      title: '创作工具',
      desc: '人工智能编程工具全量支持',
      pic: require('@/assets/image/School/DesignTools.png')
    },
    {
      title: '课程资源',
      desc: '海量优质编程课程迭代更新',
      pic: require('@/assets/image/School/CourseResources.png')
    },
    {
      title: '教务管理',
      desc: '全校教务动态更新云端监管',
      pic: require('@/assets/image/School/TeachingManagement.png')
    },
    {
      title: '在线备课',
      desc: '全校教师教学内容在线备课',
      pic: require('@/assets/image/School/OnlineLessonPreparation.png')
    },
    {
      title: '班级管理',
      desc: '全校教务班级分配自主编排',
      pic: require('@/assets/image/School/ClassManagement.png')
    },
    {
      title: '学生管理',
      desc: '全校学生用户数据自主管理',
      pic: require('@/assets/image/School/StudentsManagement.png')
    },
    {
      title: '作品管理',
      desc: '全校学生用户数据自主管理',
      pic: require('@/assets/image/School/WorksManagement.png')
    },
    {
      title: '教学跟踪',
      desc: '全校教师教学过程实时跟踪',
      pic: require('@/assets/image/School/TeachingTrack.png')
    },
    {
      title: '学情数据',
      desc: '全校学生学情数据动态分析',
      pic: require('@/assets/image/School/StudyExam.png')
    },
    {
      title: '分析报告',
      desc: '全校教务分析报告实时发布',
      pic: require('@/assets/image/School/AnalysisReport.png')
    },
    {
      title: '活动发布',
      desc: '全校教务活动赛事自主发布',
      pic: require('@/assets/image/School/ActivityDeploy.png')
    },
    {
      title: '资源对接',
      desc: '教务教学赛事领域资源对接',
      pic: require('@/assets/image/School/ResourceJoint.png')
    }
  ],
  bodyData2: [
    {
      title: '大屏展示',
      desc: '大屏展示辖区人工智能编程教育成果、教学等数据',
      pic: require('@/assets/image/School/BigDisplay.svg')
    },
    {
      title: '分析报告',
      desc: '在线跟踪辖区整体的人工智能编程教育情况，制定统一发展战略',
      pic: require('@/assets/image/School/AnalysisReport.svg')
    },
    {
      title: '发展规划',
      desc: '提供规划辖区人工智能编程教育发展的动态配置面板',
      pic: require('@/assets/image/School/DevelopmentPlanning.svg')
    },
  ],
  bodyData3: [
    {
      title: '教务管理',
      desc: '自主管理教务活动，班级结构全时段编排',
      pic: require('@/assets/image/School/EducationalAdministration.svg')
    },
    {
      title: '教师管理',
      desc: '自主管理教师权限，教学进度全方位展示',
      pic: require('@/assets/image/School/TeacherManagement.svg')
    },
    {
      title: '学生管理',
      desc: '自主管理学生账号，学情信息全方位掌握',
      pic: require('@/assets/image/School/StudentManagement.svg')
    },
  ],
  bodyData4: [
    {
      title: '课前管理',
      desc: '海量教学课程免费获取，一键发布教务材料',
      pic: require('@/assets/image/School/ClassBefore.svg')
    },
    {
      title: '课中管理',
      desc: '课堂管控触达每位学生，大规模上课简易操作',
      pic: require('@/assets/image/School/ClassMedium.svg')
    },
    {
      title: '课后管理',
      desc: '一键发布作业、集中管控学生课后学习情况',
      pic: require('@/assets/image/School/ClassAfter.svg')
    },
  ],
  bodyData5: [
    {
      title: '多种语言',
      desc: '平台免费开放多种编程语言创作入口，一键创作',
      pic: require('@/assets/image/School/MultiLanguage.svg')
    },
    {
      title: '海量课程',
      desc: '平台免费开放多种编程语言的课程，一键获取',
      pic: require('@/assets/image/School/MultiCourse.svg')
    },
    {
      title: '丰富作品',
      desc: '平台提供作品交流学习社区，一键改编热门作品',
      pic: require('@/assets/image/School/MultiWorks.svg')
    },
  ],
  bodyData6: [
    {
      title: '陪伴学习',
      desc: '工作之余的碎片时间，陪伴孩子学习人工智能编程知识',
      pic: require('@/assets/image/School/AccompanyStudy.svg')
    },
    {
      title: '同步助学',
      desc: '转发孩子的优秀作品，扩大孩子的学习积极性、热爱度',
      pic: require('@/assets/image/School/SynchronizationHelpStudy.svg')
    },
    {
      title: '学情报告',
      desc: '提供完整的学情报告，根据报告查漏补缺，提高竞争力',
      pic: require('@/assets/image/School/StudyReport.svg')
    },
  ]
}
export const LABS = [
  {
    title: 'Scratch创意实验室',
    desc: '4+岁，思维+创造力 | 图形编程',
    id: 2,
    pic: require('@/assets/image/Labs/ScratchLabBg.png'),
    icon: require('@/assets/image/Home/ScratchLogo.svg'),
    gif: require('@/assets/image/Home/bg-1.svg'),
    info: `学习Scratch编程就像搭建积木作品一样简单，<br/>即使不会复杂的计算机操作，<br/>也能通过拖拽式编程，设计程序作品，<br/>能有效提高孩子的计算机思维能力、锻炼逻辑能力、<br/>创意设计能力`,
    link: '/scratch3',
    worksImg: [
      require('@/assets/image/Labs/Scratch1.jpg'),
      require('@/assets/image/Labs/Scratch2.jpg'),
      require('@/assets/image/Labs/Scratch3.jpg'),
      require('@/assets/image/Labs/Scratch1.jpg'),
      require('@/assets/image/Labs/Scratch2.jpg'),
    ]
  },
  {
    title: 'Python海龟实验室',
    desc: '7+岁，思维+创造力 | 代码编程',
    id: 7,
    pic: require('@/assets/image/Labs/pythonTurtleLabBg.png'),
    icon: require('@/assets/image/Home/pythonTurtleLogo.svg'),
    gif: require('@/assets/image/Home/bg-5.svg'),
    info: `Python turtle（海龟）模块，<br/>可以生成标准的应用程序窗口进行图形绘制。<br/>选择学习Python turtle绘图，<br/>是从Scratch图像化编程过渡到Python代码编程的最佳学习路径。`,
    link: '/coding?lab=turtle',
    worksImg: [
      require('@/assets/image/Labs/PythonH1.jpg'),
      require('@/assets/image/Labs/PythonH2.jpg'),
      require('@/assets/image/Labs/PythonH1.jpg'),
      require('@/assets/image/Labs/PythonH2.jpg'),
      require('@/assets/image/Labs/PythonH1.jpg'),
    ]
  },
  {
    title: 'Python实验室',
    desc: '7+岁，思维+创造力 | 代码编程',
    id: 4,
    pic: require('@/assets/image/Labs/PythonLabBg.png'),
    icon: require('@/assets/image/Home/PythonLogo.svg'),
    gif: require('@/assets/image/Home/bg-2.svg'),
    info: `Python是一门开源的脚本编程语言，<br/>是从图形化编程逐步过渡到代码编程的最佳实践编程语言；<br/>Python几乎无所不能，<br/>Web、机器学习、大数据分析、人工智能开发都能胜任`,
    link: '/coding?lab=python',
    worksImg: [
      require('@/assets/image/Labs/Python1.jpg'),
      require('@/assets/image/Labs/Python2.jpg'),
      require('@/assets/image/Labs/Python1.jpg'),
      require('@/assets/image/Labs/Python2.jpg'),
      require('@/assets/image/Labs/Python1.jpg'),
    ]
  },
  {
    title: 'C实验室',
    desc: '10+岁，思维+创造力 | 代码编程',
    id: 5,
    pic: require('@/assets/image/Labs/CLabBg.png'),
    icon: require('@/assets/image/Home/CLogo.svg'),
    gif: require('@/assets/image/Home/bg-3.svg'),
    info: `C语言是一种广泛使用的计算机语言，<br/>主要用于编写协议，操作系统等底层软件，<br/>平台采用纯文本编程，在线编译调试，不受运行环境影响；<br/>学习C语言，为信息学奥赛打下基础`,
    link: '/coding?lab=c',
    worksImg: [
      require('@/assets/image/Labs/C-1.jpg'),
      require('@/assets/image/Labs/C-2.jpg'),
      require('@/assets/image/Labs/C-3.jpg'),
      require('@/assets/image/Labs/C-1.jpg'),
      require('@/assets/image/Labs/C-2.jpg'),
    ]
  },
  {
    title: 'C++实验室',
    desc: '10岁，思维+创造力 | 代码编程',
    id: 6,
    pic: require('@/assets/image/Labs/CppLabBg.png'),
    icon: require('@/assets/image/Home/CppLogo.svg'),
    gif: require('@/assets/image/Home/bg-4.svg'),
    info: `C++是C语言的继承，可以采用C语言的过程化程序设计、<br/>以抽象数据类型为特点的基于对象的程序设计，<br/>以继承和多态为特点的面向对象的程序设计；学习C++，<br/>为信息学奥赛打下基础`,
    link: '/coding?lab=cpp',
    worksImg: [
      require('@/assets/image/Labs/Cpp1.jpg'),
      require('@/assets/image/Labs/Cpp2.jpg'),
      require('@/assets/image/Labs/Cpp1.jpg'),
      require('@/assets/image/Labs/Cpp2.jpg'),
      require('@/assets/image/Labs/Cpp1.jpg'),
    ]
  }
]
export const TEACHERS = {
  data1: [
    {
      desc: '全部课程研发人员均来自清华大学、北京大学、重庆大学等985高校知名教授',
      pic: require('@/assets/image/Teachgroup/College.png')
    },
    {
      desc: '全部平台技术研发人员均来自IBM、微软、百度、腾讯、阿里等国内外名企IT企业',
      pic: require('@/assets/image/Teachgroup/ITLogo.png')
    },
    {
      desc: '全部教师均是计算机、软件相关专业教师，同时具备教师资格证、青少年软件编程教育从业证书',
      pic: require('@/assets/image/Teachgroup/ComputerLogo.png')
    },
    {
      desc: '全部课程体系、教学案例均对标Computer Science Standards K-12系列标准',
      pic: require('@/assets/image/Teachgroup/CSTA.png')
    }
  ],
  data2: [
    {
      title: '著名教师1',
      desc: '著名教师的第一个头衔',
      pic: require('@/assets/image/course.png')
    },
    {
      title: '著名教师2',
      desc: '著名教师的第一个头衔',
      pic: require('@/assets/image/course.png')
    },
    {
      title: '著名教师3',
      desc: '著名教师的第一个头衔',
      pic: require('@/assets/image/course.png')
    },
    {
      title: '著名教师4',
      desc: '著名教师的第一个头衔',
      pic: require('@/assets/image/course.png')
    }
  ],
  data3: [
    {
      title: '著名教师5',
      desc: '著名教师的第一个头衔',
      pic: require('@/assets/image/course.png')
    },
    {
      title: '著名教师6',
      desc: '著名教师的第一个头衔',
      pic: require('@/assets/image/course.png')
    },
    {
      title: '著名教师7',
      desc: '著名教师的第一个头衔',
      pic: require('@/assets/image/course.png')
    },
    {
      title: '著名教师8',
      desc: '著名教师的第一个头衔',
      pic: require('@/assets/image/course.png')
    },
    {
      title: '著名教师9',
      desc: '著名教师的第一个头衔',
      pic: require('@/assets/image/course.png')
    },
    {
      title: '著名教师10',
      desc: '著名教师的第一个头衔',
      pic: require('@/assets/image/course.png')
    },
    {
      title: '著名教师11',
      desc: '著名教师的第一个头衔',
      pic: require('@/assets/image/course.png')
    },
    {
      title: '著名教师12',
      desc: '著名教师的第一个头衔',
      pic: require('@/assets/image/course.png')
    }
  ]
}
export const CURRICULUMS = [{
  "workFile": "1278338546453549057",
  "collectNum": 0,
  "workType_dictText": "Scratch3.0",
  "workName": "Scratch作品",
  "delFlag": 0,
  "userId": "e9ca23d68d884d4ebb19d07889727dae",
  "createBy": "admin",
  "viewNum": 0,
  "hotNum": 1,
  "createTime": "2020-07-01 22:43:55",
  "workCover": "1278338545446916097",
  "concernsNum": 1,
  "workType": "2",
  "sysOrgCode": "A01",
  "id": "1278338548789776386",
  "courseId_dictText": null,
  "userId_dictText": "",
  "courseId": "",
  "starNum": 0
}, {
  "workFile": "1278613613938855937",
  "collectNum": 0,
  "workType_dictText": "Scratch3.0",
  "updateTime": "2020-07-02 16:56:55",
  "workName": "Scratch作品1",
  "delFlag": 0,
  "userId": "e9ca23d68d884d4ebb19d07889727dae",
  "createBy": "admin",
  "viewNum": 0,
  "createTime": "2020-07-02 16:56:55",
  "updateBy": "admin",
  "workCover": "1278613612873502721",
  "workType": "2",
  "sysOrgCode": "A01",
  "id": "1278613585908322306",
  "courseId_dictText": null,
  "userId_dictText": "",
  "courseId": "",
  "starNum": 0
}, {
  "workFile": "1322883542569312258",
  "collectNum": 0,
  "workType_dictText": "ScratchJr",
  "updateTime": "2020-11-01 20:49:49",
  "workName": "大象17",
  "delFlag": 0,
  "userId": "e9ca23d68d884d4ebb19d07889727dae",
  "createBy": "admin",
  "viewNum": 0,
  "createTime": "2020-11-01 20:49:49",
  "updateBy": "admin",
  "workCover": "1322883542107938817",
  "workType": "3",
  "sysOrgCode": "A01",
  "id": "1322883013600468994",
  "courseId_dictText": null,
  "userId_dictText": "",
  "courseId": "",
  "starNum": 0
}, {
  "workFile": "1355021718335361026",
  "collectNum": 0,
  "workType_dictText": "Scratch3.0",
  "workName": "学生1的作品A",
  "delFlag": 0,
  "userId": "8e7858afe60843758903010094563c5a",
  "createBy": "学生1",
  "viewNum": 0,
  "createTime": "2021-01-29 13:15:27",
  "workCover": "1355021718138228738",
  "workType": "2",
  "sysOrgCode": "A04A01A01A01",
  "id": "1355021718545076226",
  "courseId_dictText": null,
  "userId_dictText": "",
  "courseId": "",
  "starNum": 0
}, {
  "workFile": "1355022334331817986",
  "collectNum": 0,
  "workType_dictText": "Scratch3.0",
  "workName": "Admin的Scratch作品1",
  "delFlag": 0,
  "userId": "e9ca23d68d884d4ebb19d07889727dae",
  "createBy": "admin",
  "viewNum": 0,
  "createTime": "2021-01-29 13:17:55",
  "workCover": "1355022336252809218",
  "workType": "2",
  "sysOrgCode": "A01",
  "id": "1355022336483495937",
  "courseId_dictText": null,
  "userId_dictText": "",
  "courseId": "",
  "starNum": 0
}, {
  "workFile": "1355024941070782466",
  "collectNum": 0,
  "workType_dictText": "Scratch3.0",
  "workName": "学生2的Scratch作品1",
  "delFlag": 0,
  "userId": "ed0dad9e95df418d9653ba7959bc19c0",
  "createBy": "学生2",
  "viewNum": 0,
  "createTime": "2021-01-29 13:28:16",
  "workCover": "1355024940802347009",
  "workType": "2",
  "sysOrgCode": "A04A01A01A01",
  "id": "1355024941272109057",
  "courseId_dictText": null,
  "userId_dictText": "",
  "courseId": "",
  "starNum": 0
}, {
  "workFile": "1355025385029472257",
  "collectNum": 0,
  "workType_dictText": "Scratch3.0",
  "workName": "学生3的Scratch作品1",
  "delFlag": 0,
  "userId": "1116e72ed4cb405eb2bbd409e5bafa47",
  "createBy": "学生3",
  "viewNum": 0,
  "createTime": "2021-01-29 13:30:02",
  "workCover": "1355025384794591234",
  "workType": "2",
  "sysOrgCode": "A04A01A01A02",
  "id": "1355025385268547586",
  "courseId_dictText": null,
  "userId_dictText": "",
  "courseId": "",
  "starNum": 0
}, {
  "workFile": "1355038121973092353",
  "collectNum": 0,
  "workType_dictText": "Scratch3.0",
  "updateTime": "2021-01-29 14:20:38",
  "workName": "superadmin的作品",
  "delFlag": 0,
  "userId": "ec1c4d6009734e8a9a46219e2496485c",
  "createBy": "superadmin",
  "viewNum": 0,
  "createTime": "2021-01-29 14:20:38",
  "updateBy": "superadmin",
  "workCover": "1355038122170224641",
  "workType": "2",
  "sysOrgCode": "A05",
  "id": "1355037859044757506",
  "courseId_dictText": null,
  "userId_dictText": "",
  "courseId": "",
  "starNum": 0
}]
export const HOME = {
  teacherTitle: [
    {
      title: '20000+',
      desc: '课程服务学员',
      pic: require('@/assets/image/Home/ClassManagement.png')
    },
    {
      title: '150+',
      desc: '等级考试中心',
      pic: require('@/assets/image/Home/StudyExam.png')
    },
    {
      title: '3000+',
      desc: '机构合作单位',
      pic: require('@/assets/image/Home/Institution.png')
    },
    {
      title: '300+',
      desc: '机构合作单位',
      pic: require('@/assets/image/Home/ActivityDeploy.png')
    }
  ],
  teachers: [
    { pic: require('@/assets/image/Home/Schools/1.jpg') },
    { pic: require('@/assets/image/Home/Schools/2.jpg') },
    { pic: require('@/assets/image/Home/Schools/3.jpg') },
    { pic: require('@/assets/image/Home/Schools/4.jpg') },
    { pic: require('@/assets/image/Home/Schools/5.jpg') },
    { pic: require('@/assets/image/Home/Schools/6.jpg') },
    { pic: require('@/assets/image/Home/Schools/7.jpg') },
    { pic: require('@/assets/image/Home/Schools/8.jpg') }
  ]
}