<template>
  <component :is="type"></component>
</template>

<script>
import FixTopAndBottom from '@/components/page/FixTopAndBottom.vue';
import FixTop from '@/components/page/FixTop.vue';

export default {
  props: {
    type: {
    type: String,
    default: 'FixTop'
    }
  },
  data() {
    return {
    };
  },
  components: {
    FixTopAndBottom,
    FixTop
  },
  created(){
  },
  methods: {
  },
};
</script>

<style>

</style>
