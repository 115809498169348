<template>
  <div :style="BgStyle" class="bannger-bg">
    <slot></slot>
  </div>
</template>

<script>
import {getBannerBg} from '@/api/common'
import { mapState } from 'vuex';

export default {
    props:{
        height:{
            type: String
        },
        type:{
            type: Number,
            required: true
        },
        defaultBg:{
            type: String
        },
        bgColor:{
            type: String
        },
        forceBg:{
            type: String
        },
    },
    computed: {
        ...mapState({
            titleConfig: (state) => state.siteConfigs,
        })
    },
    watch:{
        titleConfig:{
            handler(){
                this.init()
            },
            deep: true,
            immediate: true
        },
        forceBg:{
            handler(){
                this.init()
            },
            deep: true,
            immediate: true
        }
    },
    data(){
        return {
            BgStyle:{},
        }
    },
    created(){
        //this.init()
    },
    methods:{
        init(){
            let style = {}
            if(this.height) style.height=this.height
            if(this.bgColor) style.backgroundColor=this.bgColor
            if(this.forceBg){
                style.backgroundImage = `url(${this.forceBg})`
            }
            else{
                let url = this.titleConfig['banner'+this.type]
                console.log("type:"+this.type,"url:"+url)
                url = url?url:this.defaultBg
                if(url) style.backgroundImage = `url(${url})`
            }
            this.BgStyle = style
            
        },
        getBannerBg(){
            getBannerBg(this.type).then(res=>{
                if(res.success&&res.result) style.backgroundImage = `url(${res.result})`
                else{
                    if(this.defaultBg) style.backgroundImage = `url(${this.defaultBg})`
                }

                this.BgStyle = style
            })
        }
    }
}
</script>

<style>
.bannger-bg{
    background-color: #0081EF;
    background-image: url(~@/assets/image/bg.png);
    background-size: cover;
}
</style>