import Vue from 'vue'
import Vuex from 'vuex'
import { ACCESS_TOKEN, USER_NAME, USER_INFO, USER_MULTI_DEPART, USER_DEPARTS, USER_ID } from "@/store/mutation-types"
import { getUserRole, initUserToken } from "@/api/user"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: '',
    username: '',
    realname: '',
    avatar: '',
    multi_depart: 0,
    departs: [],
    userInfo: {},
    matchList: [],
    matchDetail: {},
    matchActiveTab: {
      detail: { name: '1', type: 'img' },
      join: { name: '1', type: 'form' },
      works: { name: '1', type: 'upload' },
      result: { name: '1', type: 'result' },
    },
    courseQueryParams: {
      pageNo: 1,
      pageSize: 10,
      total: 0,
      tid: null,
      grade: '全部',
      difficulty: '全部',
      selfBuild: false,
      cover: null
    },
    siteConfigs: {
      famousTeacher: true,
      siteLogo: null,
      serviceLevel: 1,
      indexTitle1: '',
      indexTitle2: '',
      indexTitle3: '',
      schoolTitle1: '',
      schoolTitle2: '',
      schoolTitle3: '',
      portalDomain: 'http://eqibian.com/',
      backDomain: 'teaching.eqibian.com/',
      isInit: false
    }
    ,
    curriculumModelValue:true
  },
  getters: {
    userAvatar: state => {
      return state.userInfo.avatar
    }
  },
  mutations: {
    SET_SITE_CONFIGS: (state, siteConfigs) => {
      if (!siteConfigs || !siteConfigs.indexTitle1) {
        state.siteConfigs = {
          famousTeacher: true,
          siteLogo: null,
          serviceLevel: 1,
          indexTitle1: '亿启编',
          indexTitle2: '专注服务4-16岁青少年人工智能编程教育平台',
          indexTitle3: '为青少年提供人工智能编程工具、课程、竞赛等服务的一体化平台',
          schoolTitle1: '亿启编·校园',
          schoolTitle2: '一站式青少年人工智能编程教育服务平台',
          schoolTitle3: '为教育主管部门、学校、机构、教师提供编程教学管理与课程服务的一体化平台',
          portalDomain: 'http://eqibian.com/',
          backDomain: 'teaching.eqibian.com/',
          isInit: false
        }
        if (location.href.startsWith(process.env.VUE_APP_PROTAL)) state.siteConfigs.courseProvision = 1
      }

      let c = { ...state.siteConfigs, ...siteConfigs }
      state.siteConfigs = c
      state.siteConfigs.serviceLevel = siteConfigs.serviceLevel ? siteConfigs.serviceLevel : 1;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
      let expire = 7 * 24 * 60 * 60 * 1000
      Vue.ls.set(ACCESS_TOKEN, token, expire)
      //Vue.prototype.setCookie('EQBSESSION',token,expire,document.domain.split('.').slice(-2).join('.'));
      Vue.prototype.setCookie('EQBSESSION', token, expire);
    },
    SET_NAME: (state, { username, realname }) => {
      state.username = username;
      state.realname = realname;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_MULTI_DEPART: (state, multiDepart) => {
      state.multi_depart = multiDepart;
    },
    SET_DEPARTS: (state, departs) => {
      state.departs = departs;
    },
    SET_INFO: (state, info) => {
      let expire = 7 * 24 * 60 * 60 * 1000
      state.userInfo = info;
      Vue.ls.set(USER_NAME, info.username, expire)
      Vue.ls.set(USER_INFO, info, expire)
      Vue.ls.set(USER_ID, info.id, expire)
    },
    // 赛事
    SET_MATCH_LIST: (state, matchList) => {
      state.matchList = matchList;
    },
    SET_MATCH_DETAIL: (state, matchDetail) => {
      state.matchDetail = matchDetail;
    },
    CHANGE_MATCH_ACTIVE_TAB: (state, matchActiveTab) => {
      state.matchActiveTab = matchActiveTab;
    },
    SET_COURSE_QUERY: (state, queryParams) => {
      state.courseQueryParams = queryParams;
    },
    SET_CURRICULUM_MODEL_VALUE: (state, value) => {
      state.curriculumModelValue = value;
    },
  },
  actions: {
    SetSiteConfigs({ commit }, data) {
      commit('SET_SITE_CONFIGS', data);
    },
    SetCourseQuery({ commit }, data) {
      commit('SET_COURSE_QUERY', data);
    },
    //初始化用户token
    initUserToken({ commit }, ticket) {
      return new Promise((resolve, reject) => {
        initUserToken(ticket).then(res => {
          let data = res.result
          let token = data && data.token ? data.token : null
          commit('SET_TOKEN', token)

          if (data && data.userinfo) {
            //alert("initUserToken:"+data.userinfo.city)
            commit('SET_INFO', data.userinfo)
          }
          resolve(token)
        }).catch(error => {
          reject(null)
        })
      })
    },
    //初始化用户角色 校长系统
    InitUserRole({ commit }) {
      return new Promise((resolve, reject) => {
        getUserRole().then(res => {
          if (!res.success || !res.result || !res.result.userInfo) {
            reject(null)
          }
          let userInfo = res.result.userInfo
          commit('SET_INFO', userInfo);
          resolve(userInfo.role)
        }).catch(e => {
          console.error('InitUserRole error:', e)
          reject(null)
        })
      })
    },
    LoginUser({ commit }, data) {
      let token = data.token
      let userInfo = data.userInfo
      let expire = 7 * 24 * 60 * 60 * 1000
      let name = userInfo.username || userInfo.realname
      Vue.ls.set(ACCESS_TOKEN, token, expire)
      Vue.ls.set(USER_NAME, name, expire)
      Vue.ls.set(USER_INFO, userInfo, expire)
      Vue.ls.set(USER_MULTI_DEPART, data.multi_depart, expire)
      Vue.ls.set(USER_DEPARTS, data.departs, expire)
      Vue.ls.set(USER_ID, userInfo.id, expire)
      //alert(userInfo.city)
      commit('SET_TOKEN', token);
      commit('SET_INFO', userInfo);
      commit('SET_NAME', {
        username: name,
        realname: userInfo.realname,
      });
      commit('SET_AVATAR', userInfo.avatar);
      commit('SET_MULTI_DEPART', data.multi_depart);
      commit('SET_DEPARTS', data.departs);
    },
    ChangeNickname({ commit, state }, nickname) {
      let expire = 7 * 24 * 60 * 60 * 1000
      let userInfo = state.userInfo
      if (!userInfo || !userInfo.id) userInfo = Vue.ls.get(USER_INFO);
      userInfo.realname = nickname;
      Vue.ls.set(USER_NAME, nickname, expire)
      Vue.ls.set(USER_INFO, userInfo, expire)
      commit('SET_INFO', userInfo);
      commit('SET_NAME', {
        username: userInfo.username,
        realname: userInfo.realname,
      });
    },
    ChangeAvatar({ commit, state }, avatar) {
      let expire = 7 * 24 * 60 * 60 * 1000
      let userInfo = state.userInfo
      if (!userInfo || !userInfo.id) userInfo = Vue.ls.get(USER_INFO);
      userInfo.avatar = avatar;
      Vue.ls.set(USER_INFO, userInfo, expire)
      commit('SET_INFO', userInfo);
      commit('SET_AVATAR', avatar);
    },
    // 登出
    LogoutUser({ commit, state }) {
      commit('SET_TOKEN', '');
      commit('SET_INFO', '');
      commit('SET_NAME', '');
      commit('SET_AVATAR', '');
      commit('SET_MULTI_DEPART', '');
      commit('SET_DEPARTS', '');
      Vue.ls.remove(ACCESS_TOKEN)
      Vue.ls.remove(USER_NAME)
      Vue.ls.remove(USER_INFO)
      Vue.ls.remove(USER_MULTI_DEPART)
      Vue.ls.remove(USER_DEPARTS)
      Vue.ls.remove(USER_ID)
      Vue.prototype.delCookie('EQBSESSION')

    },
    /**
    赛事列表
     */
    SetMatchList({ commit, state }, data) {
      const { matchList } = data;
      commit('SET_MATCH_LIST', matchList);
    },
    /**
    赛事详情
     */
    SetMatchDetail({ commit, state }, data) {
      const { matchDetail } = data;
      commit('SET_MATCH_DETAIL', matchDetail);
    }
  },
  modules: {},
});
