const filters = {
    htmlFilter:(html)=>{
        //去掉html标签   去掉HTML特殊字符   去掉头尾空格
        return html.replace(/<\/?[^>]*>/g, '').replace(/&[a-zA-Z]{1,10};/g,'').replace(/[|]*\n/, '');
    }
}
export default (vm) => {
Object.keys(filters).forEach(key => {
    vm.filter(key, filters[key])
})
}