/*
 * @Author: your name
 * @Date: 2021-01-26 21:49:24
 * @LastEditTime: 2021-01-27 16:57:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /portal/src/main.js
 */
import Vue from 'vue';
import App from './App.vue';
import Storage from 'vue-ls';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import '@/assets/font/iconfont.css';
import '@/assets/css/element-variables.scss';
import 'element-ui/lib/theme-chalk/display.css';
import '@/assets/css/index.scss';
import "viewerjs/dist/viewer.css";
import plugin from '@/plugins';
import mixins from '@/mixins';
import moment from 'moment';
import Viewer from 'v-viewer';
import filter from './filter'

filter(Vue)

Vue.use(Storage, {
  namespace: 'pro__', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local', // storage name session, local, memory
});

Vue.use(ElementUI);
Vue.use(plugin);
Vue.mixin(mixins);
Viewer.setDefaults({
  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 
  'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 
  'transition': true, 'fullscreen': false, 'keyboard': true, 'url': 'data-source' }
})
Vue.use(Viewer, { name: 'viewer' });
moment.locale('zh-cn');
Vue.prototype.$moment = moment;
Vue.config.productionTip = false;
//全局注册自定义指令，用于判断当前图片是否能够加载成功，可以加载成功则赋值为img的src属性，否则使用默认图片
Vue.directive('real-img', async function (el, binding) {
  //指令名称为：real-img
  let imgURL = binding.value; //获取图片地址
  if (imgURL) {
    let exist = await imageIsExist(imgURL);
    if (exist) {
      el.setAttribute('src', imgURL);
    }
  }
});
//设置cookie,增加到vue实例方便全局调用
Vue.prototype.setCookie = (cname,cvalue,exdays, domain) => {
  var d = new Date();
  d.setTime(d.getTime()+(exdays*24*60*60*1000));
  var expires = "expires="+d.toGMTString();
  document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/" + (domain ? ("; domain=" + domain) : "");
}

//获取cookie
Vue.prototype.getCookie = (name) => {
    var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if (arr = document.cookie.match(reg))
        return (arr[2]);
    else
        return null;
}

//删除cookie
Vue.prototype.delCookie =(name) => {
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval = Vue.prototype.getCookie(name);
    if (cval != null)
      document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
  }

//全局用户角色 1: '学生',   2: '教师',  3: '考试管理员',  4: '校长管理员'
Vue.prototype.$role = {STUDENT: 1, TEACHER: 2, EXAMADMIN: 3, SCHOOLADMIN: 4}

/**
 * 检测图片是否存在
 * @param url
 */
let imageIsExist = function (url) {
  return new Promise((resolve) => {
    var img = new Image();
    img.onload = function () {
      if (this.complete == true) {
        resolve(true);
        img = null;
      }
    };
    img.onerror = function () {
      resolve(false);
      img = null;
    };
    img.src = url;
  });
};
router.afterEach(() => {
  if (document.getElementsByClassName('main-container')[0]) {
    document.getElementsByClassName('main-container')[0].scrollTop = 0;
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
