import { axios } from '@/utils/request'

export function courseTags(parameter) {
    return axios({
        url: '/v1/tags',
        method: 'get',
        params: parameter,
        notLoading: true
    })
}

export function courses(parameter) {
    return axios({
        url: '/v1/courses',
        method: 'get',
        params: parameter
    })
}

export function getCourseList(parameter) {
    return axios({
        url: '/teaching/teachingCourse/school/list',
        method: 'get',
        params: parameter
    })
}

export function courseDetail(parameter) {
    return axios({
        url: '/v1/course/detail',
        method: 'get',
        params: parameter
    })
}

// 课程单元详情
export function getCourseUnitDetail(parameter) {
    return axios({
        url: '/v1/course/unitDetail',
        method: 'get',
        params: parameter
    })
}

// 教学-课程引导步骤
export function getCourseGuide(parameter) {
    return axios({
        url: '/v1/courseUnit/guide/content',
        method: 'get',
        params: parameter
    })
}

// 教学-课程引导步骤
export function getCourseResource(parameter) {
    return axios({
        url: '/v1/courseUnit/guide/resource',
        method: 'get',
        params: parameter
    })
}

// 更新上课时间
export function updateStudyTime(parameter) {
    return axios({
        url: '/xz/attendance/attend',
        method: 'post',
        params: parameter,
        notLoading: true
    })
}

// 教学-练习题基本信息
export function getPracticeInfo(parameter) {
    return axios({
        url: '/v1/courseUnit/guide/exercise',
        method: 'get',
        params: parameter
    })
}

// 获取练习题列表
export function getPracticeList(id) {
    return axios({
        url: `/xzsstu/exam/paper/select/${id}`,
        method: 'post',
    })
}
// 提交练习题
export function submitAnswer(parameter) {
    return axios({
        url: '/xzsstu/exampaper/answer/answerSubmit',
        method: 'post',
        data: parameter
    })
}

// 老师提交评价
export function submitEvalute(parameter) {
    return axios({
        url: '/xz/classWork/evaluate',
        method: 'put',
        data: parameter
    })
}

// 获取评价
export function getEvalute(parameter) {
    return axios({
        url: '/xz/classWork/evaluate/' + parameter,
        method: 'get'
    })
}

// 获取评价
export function getEvaluteByStu(parameter) {
    return axios({
        url: '/xz/school/student/center/plan/evaluate' + parameter,
        method: 'get'
    })
}

// 道具列表
export function getProps(parameter) {
    return axios({
        url: '/xz/classroom/interaction/page',
        method: 'get',
        params: parameter
    })
}

// 学生列表
export function getStudents(parameter) {
    return axios({
        url: '/xz/classroom/prop/queryStudent',
        method: 'post',
        params: parameter
    })
}

// 轮询获取学生获得的道具
export function getPropsBySudent(parameter) {
    return axios({
        url: '/xz/classroom/prop/query',
        method: 'post',
        params: parameter,
        notLoading: true
    })
}

// 老师下发道具
export function sendPropApi(parameter) {
    return axios({
        url: '/xz/classroom/prop/add',
        method: 'post',
        data: parameter
    })
}

// 我的道具列表
export function getMyProps(parameter) {
    return axios({
        url: '/xz/classroom/prop/page',
        method: 'get',
        params: parameter
    })
}

// 作品报告
export function getWorkReport(parameter) {
    return axios({
        url: '/teaching/TeachingWorkDetail/getById',
        method: 'get',
        params: parameter
    })
}

// 作品分析-我的道具列表
export function getMyPropList(parameter) {
    return axios({
        url: '/teaching/WorkView/page',
        method: 'get',
        params: parameter
    })
}


//记录课程使用次数
export function recordCourseTimes(parameter) {
    return axios({
        url: '/teaching/teachingCourseUserAction/add',
        method: 'post',
        data: parameter
    })
}