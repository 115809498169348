import Vue from 'vue'
import axios from 'axios';
import { getToken } from '@/utils/auth';
import {toLogin} from '@/utils'
import { Notification, MessageBox } from 'element-ui';
import { ACCESS_TOKEN } from "@/store/mutation-types"
import store from '@/store'
import {Loading} from 'element-ui';


// 创建axios实例
const baseConfig = {
  baseURL: "/api",
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 1000 * 30, // request timeout
};
const instance = axios.create(baseConfig);

axios.defaults.retry = 2; // 在第一个失败的请求之后重试该请求的次数
axios.defaults.retryDelay = 1000; // 在失败的请求之间等待的毫秒数（默认为1）
// 设置post请求头
// instance.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
// service.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
const err = (error) => {
  cancelLoding();
  if (error.response) {
    let data = error.response.data
    const token = Vue.ls.get(ACCESS_TOKEN)
    console.log(data.message);
    // console.log("------异常响应------",token)
    // console.log("------异常响应------",error.response.status)
    switch (error.response.status) {
      case 403:
        Notification.error({ title: '系统提示', message: '拒绝访问',duration: 4000})
        break
      case 500:
        if (token && data.message == "Token失效，请重新登录") {
          if (data.path !== '/api/teaching/community/myConcerns') {
            Notification.error({ title: '系统提示', message: 'Token失效，请重新登录!', duration: 4000 })
            MessageBox({
              type: 'error',
              title: '登录已过期',
              message: '很抱歉，登录已过期，请重新登录',
              confirmButtonText: '重新登录',
              closeOnPressEscape: false,
              showClose: false,
              mask: false,
              center: true,
              callback: action => {
                store.dispatch('LogoutUser');
                location.href = "/login?redirect_uri=" + encodeURIComponent(location.href);
                //toLogin()
              }
            }).then(() => {
              store.dispatch('LogoutUser');
              window.location.reload();
            });
          } else {
            store.dispatch('LogoutUser');
            window.location.reload();
          }
          // update-end- --- author:scott ------ date:20190225 ---- for:Token失效采用弹框模式，不直接跳转----
        }else if(data.message == "账号已在其他处登录"){
          Notification.error({ title: '系统提示', message: '账号已在其他处登录!', duration: 4000 })
          store.dispatch('LogoutUser');
          location.href = "/login?redirect_uri=" + encodeURIComponent(location.href);
          //toLogin()
        } else {
          if (data.path !== '/api/teaching/community/myConcerns') {
            Notification.error({ title: '系统提示', message: '您还未登录!', duration: 4000 })
          }
        }
        break
      case 404:
          Notification.error({ title: '系统提示', message:'很抱歉，资源未找到!',duration: 4000})
        break
      case 504:
        Notification.error({ title: '系统提示', message: '网络超时'})
        break
      case 401:
        Notification.error({ title: '系统提示', message:'未授权，请重新登录',duration: 4000})
        if (token) {
          store.dispatch('LogoutUser');
          window.location.reload();
        }
        break
      default:
        Notification.error({
          title: '系统提示',
          message: data.message,
          duration: 4000
        })
        break
    }
  } else {
    error.message = '连接服务器失败!'
  }
  return Promise.reject(error)
};


let loading;
//内存中正在请求的数量
let loadingNum=0;
function startLoading() {
	if(loadingNum==0){
		loading = Loading.service({
		  lock: true,
		  text: '拼命加载中...',
		  background:'rgba(255,255,255,0.5)',
		})
	}
	//请求数量加1
	loadingNum++;
}
function cancelLoding() {
  //请求数量减1
	loadingNum--
	if(loadingNum<=0){
		loading.close()
	}
}

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(config => {
  const token = Vue.ls.get(ACCESS_TOKEN)
  if (token) {
    config.headers[ 'X-Access-Token' ] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  if(config.method=='get'){
    if(config.url.indexOf("sys/dict/getDictItems")<0){
      config.params = {
        _t: Date.parse(new Date())/1000,
        ...config.params
      }
    }
  }
  if(!config.notLoading) startLoading();
  return config
},(error) => {
  return Promise.reject(error)
})

// response interceptor
instance.interceptors.response.use((response) => {
  cancelLoding();
  return response.data
},  err);



// export default instance;

export {
  instance as axios
}
