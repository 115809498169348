<template>
    <el-dialog
    title="作品报告"
    :visible.sync="reportVisible"
    width="400px"
    :before-close="handleClose"
    class="work-report">
      <div slot="title" class="wr-title">作品报告</div>
      <!--fileType 1、2 stratch-->
    <template v-if="course.fileType==1 || course.fileType==2">
        <el-descriptions :column="1" labelClassName="lbClass" contentClassName="lbClass">
            <el-descriptions-item label="作品名称">{{course.workName}}</el-descriptions-item>
            <el-descriptions-item label="作者">{{course.createBy}}</el-descriptions-item>
            <el-descriptions-item label="代码行数">{{course.lineTotal?course.lineTotal:0}}</el-descriptions-item>
            <el-descriptions-item label="角色数量">{{course.roleTotal?course.roleTotal:0}}</el-descriptions-item>
        </el-descriptions>

        <el-divider></el-divider>

        <el-table
            :data="reportList"
            height="300">
            <el-table-column
            prop="opcodes"
            label="代码块名称"
            width="180">
            </el-table-column>
            <el-table-column
            prop="num"
            label="使用次数"
            width="180"
            align="center">
        </el-table-column>
        </el-table>
      </template>
      <template v-else>
        <el-descriptions :column="1" labelClassName="lbClass" contentClassName="lbClass">
            <el-descriptions-item label="作品名称">{{course.workName}}</el-descriptions-item>
            <el-descriptions-item label="作者">{{course.createBy}}</el-descriptions-item>
        </el-descriptions>

        <el-divider></el-divider>

        <el-descriptions :column="2" labelClassName="lbClass" contentClassName="lbClass">
            <el-descriptions-item label="代码行数">{{course.lineTotal?course.lineTotal:0}}</el-descriptions-item>
            <el-descriptions-item label="代码执行时间">{{course.times?course.times:0}}秒</el-descriptions-item>
            <el-descriptions-item label="代码输入">{{course.input?course.input:''}}</el-descriptions-item>
            <el-descriptions-item label="代码输出">{{course.output?course.output:''}}</el-descriptions-item>
        </el-descriptions>
      </template>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" style="width: 120px" @click="handleClose">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {getWorkReport} from '@/api/course'

export default {
    name: "WorkReport",
    data(){
        return {
            reportVisible: false,
            reportList:[],
            report: {},
            course: {}
        }
    },
    created(){
        
    },
    methods:{
        handleClose(){
            this.reportVisible = false
        },
        initReport(course){
            if(course){
                console.log(course)
                // course.lineTotal = 0
                // course.roleTotal = 0
                this.course = course
                //this.reportList = [{name:"移动", num: 10},{name:"改变造型", num: 20},{name:"左转", num: 1},{name:"右转", num: 13},{name:"移动", num: 10},{name:"改变造型", num: 20},{name:"左转", num: 1},{name:"右转", num: 13}]

                getWorkReport({id: course.id}).then(res => {
                    if(res.success){
                        let r = res.result
                        this.$set(this.course, "lineTotal", (r && r.lineTotal) ? r.lineTotal : 0)
                        this.$set(this.course, "roleTotal", (r && r.roleTotal) ? r.roleTotal : 0)
                        if(r.opcode_num) this.reportList = r.opcode_num
                    }
                })
                this.reportVisible = true
            }
            else{
                this.$message.info('请选择需要查看的作品')
            }
        }
    }
}
</script>

<style>
.lbClass{
    color: #000;
}
.work-report .el-divider--horizontal{
    margin: 10px 0px;
}
.work-report .el-dialog__header{
    padding: 0px;
}
.work-report .el-icon-close:before{
    color: #ffffff;
    position: relative;
    top: -5px;
    left: 7px;
}
.work-report .el-dialog__footer{
    text-align: center;

}
.wr-title{
    color: #ffffff;
    font-size: 18px;
    background: #409eff;
    padding: 12px;
    text-align: center;

}
</style>