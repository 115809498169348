/*
 * @Author: your name
 * @Date: 2021-01-25 14:40:01
 * @LastEditTime: 2021-01-25 14:42:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /scratch_project/src/plugins/dayjs.js
 */
import dayjs from 'dayjs';
import './dayjs-zhcn';

dayjs.locale('zh-cn');

export default dayjs;
