<template>
  <div class="m-carousel-label">
    <div
      class="arrow left"
      @click="changeLabelArrow('last')"
    >
      <i class="el-icon-arrow-left"></i>
    </div>
    <div
      v-for="(item,index) in labels"
      :key="index"
      @click="changeLabelItem(index)"
      :class="active == index ? 'active item' : 'item'"
    >{{item.bannerName}}</div>
    <div
      class="arrow right"
      @click="changeLabelArrow('next')"
    >
      <i class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BannerLabel',
  props: {
    active: {
      type: Number,
      default: 0
    },
    labels: {}
  },
  data() {
    return {
    }
  },
  methods: {
    changeLabelItem(index) {
      this.$emit('labelChange', index);
    },
    changeLabelArrow(type) {
      this.$emit('labelArrow', type);
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/views/carousel-label.scss';
</style>