<!--
 * @Author: your name
 * @Date: 2021-01-26 21:49:24
 * @LastEditTime: 2021-01-27 17:25:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /portal/src/App.vue
-->
<template>
  <Page type="FixTop"></Page>
</template>

<script>
import { getConfigs } from'@/api/common'
import Page from '@/components/page/Index.vue';
export default {
  data() {
    return {
    };
  },
  components: {
    Page
  },
  async created(){
    await this.getSchoolConfig().then(siteConfigs => {
      this.$store.dispatch('SetSiteConfigs', siteConfigs);
    })
    
  },
  methods: {
    getSchoolConfig(){
     return new Promise((resolve, reject)=>{
        getConfigs().then( res => {
            let data = res.result;
            if(res.success && data){
                let siteConfigs = {};
                  
                let bannerConfig = data.bottomChart ? JSON.parse(data.bottomChart).bottomChart : {}
                siteConfigs = {
                  ...bannerConfig
                }
                siteConfigs.serviceLevel = data.serviceLevel?data.serviceLevel:1
                if(data.siteConfig && data.siteConfig.length > 0){
                  let siteConfigArray = JSON.parse(data.siteConfig);
                  siteConfigs.indexTitle1 = siteConfigArray['【首页】标题1']
                  siteConfigs.indexTitle2 = siteConfigArray['【首页】副标题1']
                  siteConfigs.indexTitle3 = siteConfigArray['【首页】副标题2']
                  siteConfigs.schoolTitle1 = siteConfigArray['【校园】标题1']
                  siteConfigs.schoolTitle2 = siteConfigArray['【校园】副标题1']
                  siteConfigs.schoolTitle3 = siteConfigArray['【校园】副标题2']
                  siteConfigs.siteLogo = data.siteLogo
                  siteConfigs.portalDomain = data.portalDomain
                  siteConfigs.backDomain = data.backDomain
                  siteConfigs.isInit = true
                  siteConfigs.courseProvision = data.courseProvision
                  siteConfigs.famousTeacher = data.famousTeacher
                }
                resolve(siteConfigs)
            }
            else resolve(null)
        }).catch(e=>{resolve(null)})
      })
      
    }
  },
};
</script>

<style>
  .tip-text{
    display: block;
    text-align: center;
    margin: 10px auto;
  }
  .el-menu.el-menu--horizontal{
    border-bottom: 0px !important;
  }
  .full-page{
    height: 100%;
    overflow: hidden;
  }
  .full-page .footer{
    color: #ffffff; position: absolute; bottom: 10px; left: 50%; margin-left: -153px; font-size: 12px;
  }
  @media only screen and (max-width: 1440px) {
    .full-page{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>
