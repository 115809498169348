import { axios } from '@/utils/request'

export function getWorks(parameter) {
    return axios({
        url: '/teaching/community/pub/works',
        method: 'get',
        params: parameter
    })
}

export function getCoders(parameter) {
    return axios({
        url: '/teaching/community/pub/coders',
        method: 'get',
        params: parameter
    })
}

export function putLikes(parameter, isCan) {
    return axios({
        url: `/teaching/community/${parameter.id}${isCan ? '/cancel' : ''}/concerns`,
        method: 'put',
        params: parameter
    })
}

export function getBanner(parameter) {
    return axios({
        url: '/teaching/community/pub/topwork',
        method: 'get',
        params: parameter
    })
}

