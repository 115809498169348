/*
 * @Author: your name
 * @Date: 2021-01-25 14:14:23
 * @LastEditTime: 2021-01-25 14:14:35
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /scratch_project/src/mixins/index.js
 */

export default {};
