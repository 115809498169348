/*
 * @Author: your name
 * @Date: 2021-01-25 14:15:13
 * @LastEditTime: 2021-01-27 00:26:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /scratch_project/src/plugins/eventBus.js
 */
import Vue from 'vue';
export default new Vue();
