/*
 * @Author: your name
 * @Date: 2021-01-25 14:14:58
 * @LastEditTime: 2021-01-27 00:26:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /scratch_project/src/plugins/index.js
 */
import eventBus from './eventBus';
import dayjs from './dayjs';

export default {
  install: (app) => {
    Object.assign(app.prototype, {
      $bus: eventBus, // 自定义事件
      $dayjs: dayjs,
    });
  },
};
