<template>
  <div :class="['portal-title', {small: isSmall, white: white}]">
    <p class="title">{{title}}</p>
    <p class="desc">{{desc}}</p>
  </div>
</template>

<script>
export default {
  name: 'Portal-title',
  props: {
    title: {
      type: String,
      default: 'test'
    },
    desc: {
      type: String,
      default: 'test_desc'
    },
    isSmall: {
      type: Boolean,
      default: false
    },
    white: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.portal-title {
  width: 100%;
  text-align: center;
  p.title {
    font-size: 30px;
    color: #333;
    font-weight: bold;
    line-height: 1;
  }
  p.desc {
    font-size: 16px;
    // color: #666;
    margin-top: 24px;
    line-height: 1;
  }
  &.small {
    p.title {
      font-size: 20px;
    }
    p.desc {
      font-size: 13px;
      line-height: 30px;
      margin-top: 10px;
    }
  }
  &.white {
    p {
      color: #fff;
    }
  }
}
</style>
