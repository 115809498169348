/*
 * @Author: your name
 * @Date: 2021-01-26 21:49:24
 * @LastEditTime: 2021-01-30 00:01:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /portal/src/router/index.js
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home';
import store from '../store'
import { getQueryVal, removeURLParameter } from '@/utils'
import { ACCESS_TOKEN } from '@/store/mutation-types';
import HeaderNav from '@/components/HeaderNav.vue';
const Community = () => import('../views/Community');
const QuestionBank = () => import('../views/QuestionBank');
const UserInfo = () => import('../views/User/Info.vue');
const UserCenter = () => import('../views/User/Index.vue');
const StudyData = () => import('../views/User/StudyData.vue');
const QuestionCenter = () => import('../views/User/QuestionCenter.vue');
const ShoppingCenter = () => import('../views/User/Shopping/index.vue');
const GiftDetail = () => import('../views/User/Shopping/GiftDetail.vue');
const Curriculum = () => import('../views/Curriculum');
const CurriculumInfo = () => import('../views/Curriculum/info.vue')
const CurriculumSystem = () => import('../views/Curriculum/CurriculumSystem.vue')
const Login = () => import('../views/Login');
const Campus = () => import('../views/Campus');
const Lab = () => import('../views/Lab');
const Teachers = () => import('../views/Teachers');
const Match = () => import('../views/Match/Match.vue');
const MatchPlayer = () => import('../views/Match/MatchPlayer.vue')
const MatchJudges = () => import('../views/Match/MatchJudges.vue');
const Teaching = () => import('../views/Teaching/index.vue');
const Agreement = () => import('../views/Login/Agreement.vue');
const Exam = () => import('../views/Exam/Index.vue');
const ExamPaperDo = () => import('../views/Exam/ExamPaperDo.vue');
const ExamPaperRead = () => import('../views/Exam/ExamPaperRead.vue');
const ExamPaperEdit = () => import('../views/Exam/ExamPaperEdit.vue');
const Evaluate = () => import('../views/Teaching/Evaluate.vue');
const News = () => import('../views/News/Index.vue');
const NewsDetail = () => import('../views/News/Detail.vue');
const Pay = () => import('../views/User/Shopping/Pay.vue');
const Paid = () => import('../views/User/Shopping/Paid.vue');
const MyWork = () => import('../views/User/MyWork.vue');
const Rank = () => import('../views/Rank/Rank.vue');

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    components: {
      default: Home,
      header: HeaderNav
    },
    meta: {
      menuIndex: '/',
      title: '首页'
    },
  },
  {
    path: '/community',
    name: 'community',
    components: {
      default: Community,
      header: HeaderNav
    },
    meta: {
      menuIndex: '/community',
      title: '社区'
    },
  },
  {
    path: '/studyData',
    name: 'studyData',
    components: {
      default: StudyData,
      header: HeaderNav
    },
    meta: {
      menuIndex: '/studyData',
      title: '上课日历'
    },
  },
  {
    path: '/user',
    name: 'user',
    components: {
      default: UserCenter,
      header: HeaderNav
    },
    meta: {
      menuIndex: '/user',
      title: '个人中心',
      hideFooter: true
    },
  },
  {
    path: '/userinfo',
    name: 'userinfo',
    components: {
      default: UserInfo,
      header: HeaderNav
    },
    meta: {
      menuIndex: '/userinfo',
      title: '作品中心'
    },
  },
  {
    path: '/mywork',
    name: 'MyWork',
    components: {
      default: MyWork,
      header: HeaderNav
    },
    meta: {
      menuIndex: '/mywork',
      title: '作品分析'
    },
  },
  {
    path: '/questionCenter',
    name: 'questionCenter',
    components: {
      default: QuestionCenter,
      header: HeaderNav
    },
    meta: {
      menuIndex: '/questionCenter',
      title: '题目中心'
    },
  },
  {
    path: '/shoppingCenter',
    name: 'shoppingCenter',
    components: {
      default: ShoppingCenter,
      header: HeaderNav
    },
    meta: {
      menuIndex: '/shoppingCenter',
      title: '礼物商城'
    },
  },
  {
    path: '/pay',
    name: 'Pay',
    components: {
      default: Pay,
      header: HeaderNav
    },
    meta: {
      menuIndex: '/pay',
      title: '充值'
    },
  },
  {
    path: '/paid',
    name: 'Paid',
    components: {
      default: Paid,
      header: HeaderNav
    },
    meta: {
      menuIndex: '/paid',
      title: '支付完成'
    },
  },
  {
    path: '/giftDetail',
    name: 'giftDetail',
    components: {
      default: GiftDetail,
      header: HeaderNav
    },
    meta: {
      menuIndex: '/giftDetail',
      title: '商品详情'
    },
  },
  {
    path: '/curriculum',
    name: 'curriculum',
    components: {
      default: Curriculum,
      header: HeaderNav
    },
    meta: {
      menuIndex: '/curriculum',
      title: '课程',
      keepAlive: true
    },
  },
  {
    path: '/questionBank',
    name: 'questionBank',
    components: {
      default: QuestionBank,
      header: HeaderNav
    },
    meta: {
      menuIndex: '/questionBank',
      title: '题库'
    },
  },
  {
    path: '/curriculumsystem',
    name: 'curriculumsystem',
    components: {
      default: CurriculumSystem,
      header: HeaderNav
    },
    meta: {
      menuIndex: '/curriculumsystem',
      title: '课程介绍'
    },
  },
  {
    path: '/curriculum-info',
    name: 'curriculum-info',
    components: {
      default: CurriculumInfo,
      header: HeaderNav
    },
    meta: {
      menuIndex: '/curriculum',
      title: '课程单元'
    },
  },
  {
    path: '/login',
    name: 'login',
    components: {
      default: Login,
      header: HeaderNav
    },
    meta: {
      title: '统一认证中心'
    }
  },
  {
    path: '/campus',
    name: 'campus',
    components: {
      default: Campus,
      header: HeaderNav
    },
    meta: {
      menuIndex: '/campus',
      title: '校园'
    },
  },
  {
    path: '/lab',
    name: 'lab',
    components: {
      default: Lab,
      header: HeaderNav
    },
    meta: {
      menuIndex: '/lab',
      title: '实验室'
    },
  },
  {
    path: '/teachers',
    name: 'teachers',
    components: {
      default: Teachers,
      header: HeaderNav
    },
    meta: {
      menuIndex: '/teachers',
      title: '名师团'
    },
  },
  {
    path: '/ec',
    name: 'exam',
    components: {
      default: Exam,
      header: HeaderNav
    },
    meta: {
      menuIndex: '/ec',
      title: '考试中心'
    },
  },
  /*{
    path: '/do',
    name: 'ExamPaperDo',
    components: {
      default: ExamPaperDo,
      header: HeaderNav
    },
    meta: {
      menuIndex: '/ec',
      title:'试卷答题'
    },
  },
  {
    path: '/edit',
    name: 'ExamPaperEdit',
    components: {
      default: ExamPaperEdit,
      header: HeaderNav
    },
    meta: {
      menuIndex: '/ec',
      title:'试卷批改'
    },
  },
  {
    path: '/read',
    name: 'ExamPaperRead',
    components: {
      default: ExamPaperRead,
      header: HeaderNav
    },
    meta: {
      menuIndex: '/ec',
      title:'试卷查看'
    },
  },*/
  {
    path: '/match',
    name: 'match',
    components: {
      default: Match,
      // header: HeaderNav
    },
    meta: {
      menuIndex: '/match',
      title: '重庆市电子学会比赛系统'
    },
  },
  {
    path: '/match-player',
    name: 'match-player',
    components: {
      default: MatchPlayer,
      // header: HeaderNav
    },
    meta: {
      menuIndex: '/match',
      title: '重庆市电子学会比赛系统'
    },
  },
  {
    path: '/match-judges',
    name: 'match-judges',
    components: {
      default: MatchJudges,
      // header: HeaderNav
    },
    meta: {
      menuIndex: '/match',
      title: '重庆市电子学会比赛系统'
    },
  },
  {
    path: '/teaching',
    name: 'teaching',
    components: {
      default: Teaching,
      header: HeaderNav
    },
    meta: {
      menuIndex: '/teaching',
      title: '课程学习',
      hideFooter: true
    },
  },
  {
    path: '/evaluate',
    name: 'evaluate',
    components: {
      default: Evaluate,
      //header: HeaderNav
    },
    meta: {
      menuIndex: '/evaluate',
      title: '老师评价'
    },
  },
  {
    path: '/agreement',
    name: 'agreement',
    components: {
      default: Agreement,
      //header: HeaderNav
    },
    meta: {
      menuIndex: '/',
      title: '用户注册与隐私保护协议'
    },
  },
  {
    path: '/news',
    name: 'news',
    components: {
      default: News,
      header: HeaderNav
    },
    meta: {
      menuIndex: '/news',
      title: '新闻动态'
    },
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    components: {
      default: NewsDetail,
      header: HeaderNav
    },
    meta: {
      menuIndex: '/news',
      title: '新闻详情'
    },
  },
  {
    path: '/rank',
    name: 'rank',
    components: {
      default: Rank,
      header: HeaderNav
    },
    meta: {
      menuIndex: '/community',
      title: '排行中心'
    },
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

function toLogin(isLogout) {
  let reirectUri = getQueryVal('redirect_uri')
  if (reirectUri && (window.location.origin == process.env.VUE_APP_PROTAL)) {
    return window.location.reload()
  }
  let url = `${process.env.VUE_APP_PROTAL}/login?from=school&isLogout=${isLogout ? isLogout : false}&redirect_uri=${location.href}`
  if (reirectUri) {
    url = process.env.VUE_APP_PROTAL + '/login?' + location.href.split("?")[1]
  }
  window.location.href = url
}

router.beforeEach(async (to, from, next) => {
  //鉴权拦截操作
  let token = Vue.ls.get(ACCESS_TOKEN);
  const redirect = getQueryVal("redirect_uri")

  // console.log(token+"-------"+redirect)
  // if(token && redirect && redirect.indexOf(process.env.VUE_APP_PROTAL) > -1){
  //   window.location.href = redirect
  // }

  //有ticket
  const ticket = getQueryVal("ticket")
  if (ticket) {
    token = await store.dispatch('initUserToken', ticket)
    let url = getQueryVal("redirect_uri") || window.location.href
    console.log("----------removeUrl:" + removeURLParameter(url, 'ticket'))
    window.location.href = removeURLParameter(url, 'ticket')
    //window.location.href = url
  }
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  }

  if (to.path == '/login') {
    //登录 亿启编portal 直接放行
    if (window.location.origin == process.env.VUE_APP_PROTAL) return next()
    //否则，跳转到统一登录
    else return toLogin()
  }
  next();
});

export default router;
