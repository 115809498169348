import { axios } from '@/utils/request'

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function login(parameter) {
    return axios({
        url: '/v1/login',
        method: 'post',
        data: parameter
    })
}

export function reg(parameter) {
    return axios({
        url: '/v1/register',
        method: 'post',
        data: parameter
    })
}

export function sendCode(parameter) {
    return axios({
        url: '/v1/sms',
        method: 'post',
        data: parameter
    })
}

export function getWorks(parameter) {
    return axios({
        url: '/teaching/user/v1/pub/works',
        method: 'get',
        params: parameter
    })
}

export function getCollects(parameter) {
    return axios({
        url: '/teaching/user/v1/collects',
        method: 'get',
        params: parameter
    })
}

export function getUserInfo(parameter) {
    return axios({
        url: '/teaching/user/v1/pub/mine',
        method: 'get',
        params: parameter
    })
}

export function getUserRole(parameter) {
    return axios({
        url: '/xz/user/getInfo',
        method: 'get',
        params: parameter
    })
}

export function getMyConcerns(parameter) {
    return axios({
        url: '/teaching/community/myConcerns',
        method: 'post',
        params: parameter
    })
}

export function forgetPwd(parameter) {
    return axios({
        url: '/v1/findpwd/check',
        method: 'post',
        data: parameter
    })
}

export function changeNickname(parameter) {
    return axios({
        url: '/teaching/user/editProfile',
        method: 'put',
        data: parameter
    })
}

//重新获取ticket
export function getTicket(parameter) {
    return axios({
        url: '/sys/refreshTicket',
        method: 'get'
    })
}

//重新获取ticket
export function checkPhone(phone) {
    return axios({
        url: '/v1/exists?phone=' + phone,
        method: 'get'
    })
}

export function getMyStudyData(parameter) {
    return axios({
        url: '/xz/school/student/center/statistics',
        method: 'get',
        params: parameter
    })
}

export function getMyLessonList(parameter) {
    return axios({
        url: '/xz/school/student/center/prepare/lesson/list',
        method: 'get',
        params: parameter
    })
}

export function getTeacherLessonList(parameter) {
    return axios({
        url: '/xz/school/student/center/prepare/lesson/list/teacher',
        method: 'get',
        params: parameter
    })
}

export function getCampusList(param) {
    return axios({
        url: '/xz/school/campus/list',
        method: 'get',
        params: param
    })
}

export function getClassroomList(param) {
    return axios({
        url: '/xz/school/campus/listClassRoom',
        method: 'get',
        params: param
    })
}

export function getUserList(param) {
    return axios({
        url: '/xz/school/classUser/pageList',
        method: 'get',
        params: param
    })
}

export function initUserToken(param) {
    return axios({
        url: "/v1/loginStatus?ticketId=" + param,
        method: 'get',
        // params:param
    })
}

export function judgePermission(param) {
    return axios({
        url: "/v1/unit/permission",
        method: 'get',
        params: param
    })
}

export function getUserQuestionData(parameter) {
    return axios({
        url: '/student/portalQuestion/summary',
        method: 'get',
        params: parameter
    })
}

export function getQuestionOptions(parameter) {
    return axios({
        url: '/student/portalQuestion/options',
        method: 'get',
        params: parameter
    })
}

export function getQuestionList(parameter) {
    return axios({
        url: '/student/portalQuestion/list',
        method: 'get',
        params: parameter
    })
}

export function collectQuestion(parameter) {
    return axios({
        url: '/student/portalQuestion/favorite',
        method: 'post',
        params: parameter
    })
}

export function getQuestionDetail(parameter) {
    return axios({
        url: '/student/portalQuestion/questionDetail',
        method: 'get',
        params: parameter
    })
}
export function submitQuestion(parameter) {
    return axios({
        url: '/student/portalQuestion/submit',
        method: 'post',
        data: parameter
    })
}

export function getUserAnswerDetail(parameter) {
    return axios({
        url: '/student/portalQuestion/answer',
        method: 'get',
        params: parameter
    })
}

//获取用户积分列表
export function getIntegralList(parameter) {
    return axios({
        url: '/mall/Integral/page',
        method: 'get',
        params: parameter
    })
}

//获取用户收货地址列表
export function getAddressList(parameter) {
    return axios({
        url: '/mall/AddressManagement/getAddress',
        method: 'get',
        params: parameter
    })
}

//新增收货地址
export function addAddress(parameter) {
    return axios({
        url: '/mall/AddressManagement/add',
        method: 'post',
        data: parameter
    })
}

//编辑收货地址
export function editAddress(parameter) {
    return axios({
        url: '/mall/AddressManagement/edit',
        method: 'put',
        data: parameter
    })
}

//删除收货地址
export function delAddress(parameter) {
    return axios({
        url: '/mall/AddressManagement/deleteById?id=' + parameter,
        method: 'delete'
    })
}

//切换成默认收货地址
export function enableDefault(parameter) {
    return axios({
        url: '/mall/AddressManagement/editDetailedAddress',
        method: 'put',
        data: parameter
    })
}

//获取用户E币列表
export function getCurrencyList(parameter) {
    return axios({
        url: '/mall/RecordsCurrency/page',
        method: 'get',
        params: parameter
    })
}

//获取商品列表
export function getGoodsList(parameter) {
    return axios({
        url: '/mall/CommodityManagement/page',
        method: 'get',
        params: parameter
    })
}

//获取单个商品
export function getGoodsDetail(parameter) {
    return axios({
        url: '/mall/CommodityManagement/getById',
        method: 'get',
        params: parameter
    })
}

//预下单
export function checkOrder(parameter) {
    return axios({
        url: '/mall/OrderManagement/checkWallet',
        method: 'post',
        data: parameter
    })
}

//下单
export function orderGoods(parameter) {
    return axios({
        url: '/mall/OrderManagement/add',
        method: 'post',
        data: parameter
    })
}

//获取用户当前E币和积分
export function getUserCurrency(parameter) {
    return axios({
        url: '/mall/wallet/getWallet',
        method: 'get',
        params: parameter
    })
}

//支付宝支付
export function alipay(parameter) {
    return axios({
        url: '/mall/alipay/pays',
        method: 'post',
        data: parameter
    })
}

//微信支付
export function wechatpay(parameter) {
    return axios({
        url: '/mall/wx-pay/htmlpay/pays',
        method: 'post',
        data: parameter
    })
}

//支付回调
export function getOrder(parameter) {
    return axios({
        url: '/mall/OrderManagement/page',
        method: 'get',
        params: parameter
    })
}

//积分兑换E币
export function integralExchange(parameter) {
    return axios({
        url: '/mall/wallet/integralExchangeE',
        method: 'put',
        data: parameter
    })
}

//获取积分兑换规则
export function getExchangeRule(parameter) {
    return axios({
        url: '/mall/PropsIntegralRule/getOne',
        method: 'get',
        params: parameter
    })
}

//获取Top5
export function getTop5(parameter) {
    return axios({
        url: '/teaching/WorkView/getTop',
        method: 'get',
        params: parameter
    })
}

//获取学习记录（饼图）
export function getPie2(parameter) {
    return axios({
        url: '/teaching/WorkView/queryAwardWinningWorks',
        method: 'get',
        params: parameter
    })
}

//获取python、c、c++ 代码行数
export function getBar2(parameter) {
    return axios({
        url: '/teaching/WorkView/queryCodeLineCount',
        method: 'get',
        params: parameter
    })
}

//获取Scratch代码行数（柱状图）
export function getBar(parameter) {
    return axios({
        url: '/teaching/WorkView/queryScratchLine',
        method: 'get',
        params: parameter
    })
}

//获取作品类型（饼图）
export function getPie(parameter) {
    return axios({
        url: '/teaching/WorkView/queryView',
        method: 'get',
        params: parameter
    })
}

export function changePwd(parameter) {
    return axios({
        url: '/user/modifyPwd',
        method: 'post',
        data: parameter
    })
}

export function changeAvatar(parameter) {
    return axios({
        url: '/oss/file/saveAvatar',
        method: 'post',
        params: parameter
    })
}

export function getAccounts(parameter) {
    return axios({
        url: '/v1/getLoginUserByPhone',
        method: 'get',
        params: parameter
    })
}

export function getTeacherList(param) {
    return axios({
        url: '/xz/school/classUser/getTeacherAndTa',
        method: 'get',
        params: param
    })
}