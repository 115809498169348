import { axios } from '@/utils/request'

export function getBanners(parameter) {
    return axios({
        url: '/qzw/rotationPic/list',
        method: 'get',
        params: parameter
    })
}

export function getConfigs(parameter) {
    return axios({
        url: '/xz/portal/home/config',
        method: 'get',
        params: parameter
    })
}

export function getFooter(parameter) {
    return axios({
        url: '/v1/getConfig',
        method: 'get',
        params: parameter
    })
}

export function getTopNews(parameter) {
    return axios({
        url: '/v1/pageNewsTop',
        method: 'get',
        params: parameter
    })
}

export function getNewsByCategory(parameter) {
    return axios({
        url: '/v1/getNewsByCat',
        method: 'get',
        params: parameter
    })
}

export function getNews(parameter) {
    return axios({
        url: '/v1/news/cat',
        method: 'get',
        params: parameter
    })
}

export function getNewsByTitle(parameter) {
    return axios({
        url: '/v1/queryNews',
        method: 'get',
        params: parameter
    })
}

export function getCategorys(parameter) {
    return axios({
        url: '/v1/pageCat',
        method: 'get',
        params: parameter
    })
}

export function getNewsDeail(parameter) {
    return axios({
        url: '/v1/getNews',
        method: 'get',
        params: parameter
    })
}

export function getTeachers(parameter) {
    return axios({
        url: '/v1/getStarByTeam',
        method: 'get',
        params: parameter
    })
}

export function getTeacherConfig(parameter) {
    return axios({
        url: '/v1/getPopularTeam',
        method: 'get',
        params: parameter
    })
}

export function downFile(url, parameter) {
    return axios({
        url: url,
        params: parameter,
        method: 'get',
        responseType: 'blob'
    })
}

export function uploadFile(parameter) {
    return axios({
        url: url,
        data: parameter,
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data',  // 文件上传
        },
    })
}

export function getBannerBg(parameter) {
    return axios({
        url: `/qzwBottomChart/get?chartType=${parameter}`,
        method: 'get'
    })
}

export function sensitiveFilter(data) {
    return axios({
        url: '/amwork/sensitiveFilter?desc=' + data.desc,
        method: 'post',
        data: data
    })
}