<template>
  <div>
    <span
      v-if="!datas || datas.length == 0"
      class="tip-text"
    >更多课程开放，请联系管理员</span>
    <ul
      class="curriculums"
      v-else
    >
      <li
        class="curriculum-item"
        v-for="item in datas"
        :key="item.id"
        style="position:relative"
      >
        <img
          class="curriculum-item-bg"
          src="~@/assets/image/curriculum/c-bg.svg"
          v-real-img="item.courseCover"
        />
        <div class="curriculum-div">
          <div class="curriculum-img">
            <!-- <img :src="item.courseCover" /> -->
          </div>
          <div class="curriculum-info">
            <p class="title">
              <el-tooltip
                v-if="item.name.length>20"
                effect="dark"
                :content="item.name"
                placement="top-start"
              >
                <a
                  href="javascript:;"
                  @click="goCurriculum(item.id)"
                  class="name"
                >{{item.name}}</a>
              </el-tooltip>
              <a
                v-else
                href="javascript:;"
                @click="goCurriculum(item.id)"
                class="name"
              >{{item.name}}</a>
              <span>{{item.ke}}课时</span>
            </p>
            <div
              class="info"
              style="-webkit-box-orient: vertical;-webkit-line-clamp: 2;display: -webkit-box;"
            >
              {{item.desc}}
            </div>
            <ul class="curriculum-tags2">
              <!-- <template v-if="item.typeName instanceof Array"> -->
              <li
                v-for="tag in item.tags"
                :key="tag.typeId"
              >{{tag.typeName}}</li>
              <!-- </template> -->
              <!-- <li v-else :key="item.typeName">{{item.typeName}}</li> -->
              <li>{{item.grade}}</li>
              <li>{{item.difficulty}}</li>
            </ul>
            <a
              class="button-3"
              href="javascript:;"
              @click="goCurriculum(item.id)"
            >学习课程</a>
            <a
              class="button-4"
              href="javascript:;"
              @click="collectOrStar(item, 2)"
            >加入收藏</a>
            <div class="curriculum-footer">
              <div class="author-img">
                <a
                  :href="item.userlink"
                  class="name"
                >
                  <el-avatar
                    class="m-avatar"
                    icon="el-icon-user-solid"
                    :src="item.avator"
                  ></el-avatar>
                  <!-- <img :src="item.avator" /> -->
                </a>
              </div>
              <span><a
                  :href="item.userlink"
                  class="name"
                >{{item.author}}</a></span>
              <!-- <span><a href="javascript:;" @click="goUserInfo(course.userId)" class="name">{{item.author}}</a></span> -->
              <p class="result">
                <span><i
                    :class="['iconfont', item.isCollect ? 'iconyishoucang0081ef active': 'iconshoucang979EB1', 'can-active']"
                    @click="collectOrStar(item, 2)"
                  ></i><label>{{item.up || 0}}</label></span>
                <span><i
                    :class="['iconfont icondianzan979EB1', item.isStar ? 'iconyidianzan0081ef active': 'icondianzan979EB1', 'can-active']"
                    @click="collectOrStar(item, 1)"
                  ></i><label>{{item.star || 0}}</label></span>
                <span><i class="iconfont iconguankancishu"></i><label>{{item.views}}</label></span>
              </p>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { putLikes } from "@/api/community"
import { getMyConcerns } from '@/api/user'
import Vue from 'vue'
export default {
  props: {
    datas: {
      type: Array,
      default() {
        return []
      }
    },
    showOther: {
      type: Boolean,
      default() {
        return false
      }
    },
    queryParam: {}
  },
  data() {
    return {
    };
  },
  watch: {
    datas(value) {
      if (Vue.ls.get('Access-Token')) {
        this.getStarAndCollect(value.map(item => item.id));
      }
    },
  },
  methods: {
    getStarAndCollect(idList) {
      getMyConcerns({ workIds: idList.join(','), type: 'COURSE' }).then(res => {
        if (res.success) {
          this.datas.forEach(item => {
            if (res.result[item.id] == 1) {
              item.isStar = true;
            } else if (res.result[item.id] == 2) {
              item.isCollect = true;
            } else if (res.result[item.id] == 3) {
              item.isCollect = true;
              item.isStar = true;
            }
          })
        }
      })
    },
    goCurriculum(id) {
      this.$store.dispatch('SetCourseQuery', this.queryParam);
      this.$router.push({ path: '/curriculum-info', query: { cid: id } });
    },
    collectOrStar(item, opeType) {
      putLikes({ id: item.id, opeType: opeType, type: 'COURSE' }, opeType == 2 ? item.isCollect : item.isStar).then(res => {
        if (res.success) {
          this.$notify({
            title: '系统提示',
            message: opeType == 2 ? (item.isCollect ? '取消收藏成功' : '收藏成功') : (item.isStar ? '取消点赞成功' : '点赞成功'),
            type: 'success'
          });
          if (opeType == 2) {
            item.isCollect = !item.isCollect;
            item.up = parseInt(item.up || 0) + (item.isCollect ? 1 : (item.up == 0 ? 0 : -1));
          } else {
            item.isStar = !item.isStar;
            item.star = parseInt(item.star || 0) + (item.isStar ? 1 : (item.star == 0 ? 0 : -1));
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/views/curriculums.scss';
</style>